import Famous1Img from '../../../assets/images/pngs/famous/08-Worst-One/01-IvankaTrump-08.png'
import Famous2Img from '../../../assets/images/pngs/famous/08-Worst-One/02-RegisPhilbin-08.png'
import Famous3Img from '../../../assets/images/pngs/famous/08-Worst-One/03-Flo-08.png'
import Famous4Img from '../../../assets/images/pngs/famous/08-Worst-One/04-SeanPenn-08.png'
import Famous5Img from '../../../assets/images/pngs/famous/08-Worst-One/05-MiloYiannopoulos-08.png'
import Famous6Img from '../../../assets/images/pngs/famous/08-Worst-One/06-JohnWilkesBooth-08.png'
import Famous7Img from '../../../assets/images/pngs/famous/08-Worst-One/07-BratzDolls-08.png'
import Famous8Img from '../../../assets/images/pngs/famous/08-Worst-One/08-LoganPaul-08.png'
import Famous9Img from '../../../assets/images/pngs/famous/08-Worst-One/09-MariahCarey-08.png'
import Famous10Img from '../../../assets/images/pngs/famous/08-Worst-One/10-DaffyDuck-08.png'

export default {
  Famous2: { img: Famous2Img, name: 'Regis Philbin' },
  Famous3: { img: Famous3Img, name: 'Flo' },
  Famous4: { img: Famous4Img, name: 'Sean Penn' },
  Famous5: { img: Famous5Img, name: 'Milo Yiannopoulos' },
  Famous6: { img: Famous6Img, name: 'John Wilkes Booth' },
  Famous7: { img: Famous7Img, name: 'Bratz Dolls' },
  Famous8: { img: Famous8Img, name: 'Logan Paul' },
  Famous9: { img: Famous9Img, name: 'Mariah Carey' },
  Famous10: { img: Famous10Img, name: 'Daffy Duck' },
  Famous1: { img: Famous1Img, name: 'Ivanka Trump' }
}
