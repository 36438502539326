import Famous1Img from '../../../assets/images/pngs/famous/12-PervertLandlord/01-STEVENSEGAL-12.png'
import Famous2Img from '../../../assets/images/pngs/famous/12-PervertLandlord/02-MaryKayLetourneau-12.png'
import Famous3Img from '../../../assets/images/pngs/famous/12-PervertLandlord/03-Ursula-12.png'
import Famous4Img from '../../../assets/images/pngs/famous/12-PervertLandlord/04-AndyDick-12.png'
import Famous5Img from '../../../assets/images/pngs/famous/12-PervertLandlord/05-L.RonHubbard-12.png'
import Famous6Img from '../../../assets/images/pngs/famous/12-PervertLandlord/06-Wario-12.png'
import Famous7Img from '../../../assets/images/pngs/famous/12-PervertLandlord/07-TedCruz-12.png'
import Famous8Img from '../../../assets/images/pngs/famous/12-PervertLandlord/08-ElizabethII-12.png'
import Famous9Img from '../../../assets/images/pngs/famous/12-PervertLandlord/09-JabbaTheHutt-12.png'
import Famous10Img from '../../../assets/images/pngs/famous/12-PervertLandlord/10-DrPhil-12.png'

export default {
  Famous2: { img: Famous2Img, name: 'Mary Kay Letourneau' },
  Famous3: { img: Famous3Img, name: 'Ursula' },
  Famous4: { img: Famous4Img, name: 'Andy Dick' },
  Famous5: { img: Famous5Img, name: 'L. Ron Hubbard' },
  Famous6: { img: Famous6Img, name: 'Wario' },
  Famous7: { img: Famous7Img, name: 'Ted Cruz' },
  Famous8: { img: Famous8Img, name: 'Elizabeth II, Queen Of England' },
  Famous9: { img: Famous9Img, name: 'Jabba The Hutt' },
  Famous10: { img: Famous10Img, name: 'Dr. Phil' },
  Famous1: { img: Famous1Img, name: 'Steven Seagal' }
}
