
// import { ActionTypes } from './actions'

export const initialState = {}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case 'SET_ACTIVE_MODAL':
      return Object.assign({}, state, { activeModal: action.modal })
    case 'UPDATE_TEST':
      return Object.assign({}, state, { answers: action.answers, updated: action.updated })
  }

  return state
}
