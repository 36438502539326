import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import * as S from './styles'
import { PersonalityTypes } from '../../data/personalityTypes'
import SocialShare from '../SocialShare/SocialShare'

@withRouter
export default class PersonalityInfo extends Component {
  state = {
    slideIndex: 0
  }

  renderTags = (tags) => {
    if (!tags) return null
    return <S.Tags>
      { tags.map((tag, index) => {
        return <S.Tag key={index}>
          { tag }
        </S.Tag>
      }) }
    </S.Tags>
  }

  renderDescription = (descriptions) => {
    if (!descriptions) return null
    return <S.Description>
      { descriptions.map((description, index) => {
        return <S.Paragraph key={index}>
          { description }
        </S.Paragraph>
      }) }
    </S.Description>
  }

  renderCareers = (careers) => {
    if (!careers) return null
    return <S.Careers>
      { careers.map((career, index) => {
        return <S.Career key={index}>{ career }</S.Career>
      }) }
    </S.Careers>
  }

  renderFamousList = (famousList) => {
    const { slideIndex } = this.state
    if (!famousList) return null
    let maxCount = 5
    let count = Object.keys(famousList).length
    let carouselDisabled = false
    if (count < maxCount) {
      maxCount = count
      carouselDisabled = true
    }

    return <S.Famous slideIndex={slideIndex} disabled={carouselDisabled}>
      { Object.values(famousList).map((famous, index) => {
        return (
          <S.Person
            key={index}
            className='slide'
            onClick={() => { this.setState({ slideIndex: index }) }}
            count={maxCount}
          >
            <S.PersonImage>
              <S.ImgWrap>
                <img src={famous.img} />
              </S.ImgWrap>
            </S.PersonImage>
            { typeof famous.name === 'string'
              ? <p>{famous.name}</p>
              : famous.name }
          </S.Person>
        )
      }) }
    </S.Famous>
  }

  renderTestIncomplete = () => {
    return <div>test is incomplete</div>
  }

  render () {
    const { match, type } = this.props
    const personalityType = type || match.params.type
    const personality = PersonalityTypes[personalityType]

    if (!personality) return this.renderTestIncomplete()

    return <S.PersonalityInfo>
      <S.HeroImg bgImg={personality.heroImg} />
      <S.InfoContent>
        <SocialShare />
        <S.InfoText>
          <S.Title>{ personality.label }</S.Title>
          { personalityType !== '17th' && <S.SubTitle>{ personalityType } ({personality.acronym})</S.SubTitle> }
          { this.renderTags(personality.tags) }
          <S.StyledDivider />
          { this.renderDescription(personality.descriptions) }
          { personalityType !== '17th'
            ? <p>Common career paths for {personalityType}s:</p>
            : <p>Careers best suited to The Fabled 17th Personality:</p> }
          { this.renderCareers(personality.careers) }
          { personalityType !== '17th'
            ? <S.CenteredTitle>Famous {personalityType}s</S.CenteredTitle>
            : <S.CenteredTitle>Suspected Fabled 17th Personalities In History</S.CenteredTitle> }
          { personality.famousImages && this.renderFamousList(personality.famousImages) }
        </S.InfoText>
      </S.InfoContent>
    </S.PersonalityInfo>
  }
}
