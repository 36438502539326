import styled, { css } from 'styled-components'

import { Colors, Type, Shadows } from 'app/styles'

export const InputWrap = styled.div`
  position: relative;
  height: 100%;
`

const sharedInputStyles = css`
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
  width: 100%;
  font-family: ${Type.FontFace};
  padding-bottom: 10px;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${Colors.lightGray};
  }
`

export const TextInput = styled.input`
  ${Type.Body};
  ${sharedInputStyles};
  box-shadow: ${Shadows.main};
  -webkit-appearance: none;

  ${props => props.error && css`
    border: 1px solid ${Colors.red};

    &:focus {
      border: 1px solid ${Colors.red};
    }
  `}

  ${props => props.transparent && css`
    border: none;
    border-bottom: 1px solid ${Colors.white};
    background: transparent;
    color: ${Colors.white};
    border-radius: 0;
    font-size: 14px;
    box-shadow: none;

    ::placeholder {
      color: ${Colors.white};
      font-weight: 100;
    }
  `}
`

export const Error = styled.div`
  ${Type.SmallBody}
  color: ${Colors.red};
  position: relative;
  height: 20px;
  margin: 4px 0 0 4px;
`

export const Label = styled.div`
  ${Type.SmallBody}
  left: 2px;
  position: relative;
  height: 20px;
  color: ${Colors.lightGray};
`
