import React from 'react'
import { Enum } from 'enumify'

import { Colors } from 'app/styles'

import {
  Beast,
  Dj,
  Chicken,
  Birthday,
  Comcast,
  Drama,
  Fabled,
  George,
  Spider,
  Jumbo,
  Karaoke,
  Murderous,
  Manhole,
  Landlord,
  Scrabble,
  Soot,
  Worst,
  LBOAIcon,
  LBOCIcon,
  LBYAIcon,
  LBYCIcon,
  LPOAIcon,
  LPOCIcon,
  LPYAIcon,
  LPYCIcon,
  UBOAIcon,
  UBOCIcon,
  UBYAIcon,
  UBYCIcon,
  UPOAIcon,
  UPOCIcon,
  UPYAIcon,
  UPYCIcon,
  BeastBg,
  DjBg,
  ChickenBg,
  BirthdayBg,
  ComcastBg,
  DramaBg,
  GeorgeBg,
  SpiderBg,
  JumboBg,
  KaraokeBg,
  MurderousBg,
  ManholeBg,
  LandlordBg,
  ScrabbleBg,
  SootBg,
  WorstBg
} from './imagePaths/images'

import {
  JumboFamous,
  DjFamous,
  KaraokeFamous,
  SpiderFamous,
  ChickenFamous,
  GeorgeFamous,
  ScrabbleFamous,
  WorstFamous,
  MurderousFamous,
  RagamuffinFamous,
  ComcastFamous,
  LandlordFamous,
  NudeFamous,
  DramaFamous,
  BirthdayFamous,
  BeastFamous,
  FabledFamous
} from './imagePaths/famous'

export class PersonalityTypes extends Enum {}
PersonalityTypes.initEnum({
  LBOC: {
    label: 'The Child On A Jumbotron',
    heroImg: Jumbo,
    bgImg: JumboBg,
    icon: LBOCIcon,
    color: Colors.teal,
    acronym: 'Lovable Barnacle Orgiastic Christ',
    tags: [
      'Sprightly',
      'Self-Assured',
      'Overeager',
      'Flailing'
    ],
    descriptions: [
      'Naturally energetic and ready at a moment’s notice to launch into exuberant, poorly coordinated performance, The Child On A Jumbotron craves nothing more than being the center of attention, whether they’re in a packed bar, on a group tour of their child’s prospective college campus, or posing for any photo whatsoever. Possessing the most rudimentary of all personality types, The Child On A Jumbotron’s uncomplicated emotional system simply supplies the LBOC with validation any time they make a spectacle of themselves, regardless of audience or circumstance. As a result, they never give a moment’s thought to such concerns as their lack of originality, the personal space of anyone in their twirling or thrusting radius, or the loss of dignity that will result from their frequent unrestrained public displays.',
      'Unique among personality groups, LBOCs are notable for being entirely devoid of self-consciousness, which is why they can often be found diving into the center of a wedding reception dance floor to perform The Worm, wearing T-shirts that read “I Don’t Get Drunk, I Get Awesome,” wandering into the background of a live local news segment to pantomime cunnilingus using their tongue and fingers, or shouting the phrase “Dilly Dilly” in every conceivable setting.',
      'Rather than putting effort into cultivating a skill that would earn them a lasting source of praise, LBOCs are happy to rely on their own slow wits to achieve short-lived notoriety wherever the opportunity presents itself, be it at a casual dinner outing or the middle of a German heritage street festival. Because if there’s one thing a Child On A Jumbotron understands, it’s that there’s no quicker way to capture the fleeting amusement of others than by yelling “Release the kraken!” at a public gathering, treating a nearby signpost as a stripper pole, or lifting up their shirt and jiggling their belly for all to see.'
    ],
    careers: [
      'Go-Go Dancer',
      'Energy Drink Pitchman',
      'Cruise Ship “Fun Admiral”',
      'Licensed Zumba Instructor',
      'Times Square Spider-Man'
    ],
    famousList: [
      { name: 'Joy Behar' },
      { name: 'Billy Mays' },
      { name: 'The Trix Rabbit' },
      { name: 'Jimmy Fallon' },
      { name: 'Dogs' },
      { name: 'Michael Avenatti' },
      { name: 'The Real Housewives' },
      { name: 'The Mask' },
      { name: 'Baby New Year' },
      { name: 'Anne Hathaway' }
    ],
    famousImages: JumboFamous
  },
  LBYC: {
    label: 'The 46-Year-Old Who Bought DJ Equipment',
    heroImg: Dj,
    bgImg: DjBg,
    icon: LBYCIcon,
    color: Colors.orange,
    acronym: 'Lovable Barnacle Persnickety Christ',
    tags: [
      'Adorable',
      'Clueless',
      'Enthusiastic',
      'Pitiable'
    ],
    descriptions: [
      'LBYCs are widely considered the most optimistic, and consequently the most tragic, individuals on the personality spectrum. Simultaneously brimming with enthusiasm and burdened with severe personal limitations, 46-Year-Olds Who Bought DJ Equipment are classic doers who aren’t afraid to grow out a chinstrap beard or give belly dancing classes a shot, wholly unaware that their mediocre, embarrassing efforts are eliciting continuous silent pity from everyone around them.',
      'However, a 46-Year-Old Who Bought DJ Equipment’s innocence is also their greatest asset, allowing them to maintain their characteristic positive outlook as they endure the endless cycle of humiliations that define their existence. Indeed, those who mock LBYCs—a list that typically includes an LBYC’s spouse, colleagues, classmates, neighbors, parents, bystanders, teachers, pastors, cashiers, and fellow commuters—always do so behind their target’s back, as they genuinely wish to avoid causing pain to someone so endearingly, heartbreakingly determined.',
      'It’s their admirable, yet entirely unfounded, hopefulness that leads 46-Year-Olds Who Bought DJ Equipment to believe they will be rewarded for taking a stab at almost anything—be it MMA training, close-up magic, or adding a leather jacket to their wardrobe—which is why they can be found thoroughly embarrassing themselves on a daily basis in all professions and hobbies. To The 46-Year-Old Who Bought DJ Equipment, the world is their oyster, and nothing, including the palpable discomfort of onlookers, is going to stop them.'
    ],
    careers: [
      'Little Caesars Franchisee',
      'Sorority House Mom',
      'Bubble Wand Demonstrator',
      'Nontraditional Student',
      'Electronics Store Sales Floor Associate'
    ],
    famousList: [
      { name: 'Chuck Schumer' },
      { name: 'The Spice Girls' },
      { name: 'Yoshi' },
      { name: 'Annie Leibovitz' },
      { name: 'The Yellow M&M' },
      { name: 'Koko' },
      { name: 'Snap' },
      { name: 'Crackle' },
      { name: 'Hirohito' },
      { name: 'Rockefeller Center Christmas Tree' }
    ],
    famousImages: DjFamous
  },
  UBYC: {
    label: 'The Karaoke Vetoer',
    heroImg: Karaoke,
    bgImg: KaraokeBg,
    icon: UBYCIcon,
    color: Colors.green,
    acronym: 'Unlovable Barnacle Persnickety Christ',
    tags: [
      'Resolute',
      'Nitpicky',
      'Overbearing',
      'Dickish'
    ],
    descriptions: [
      <p>Among the most strong-willed and influential of all personality types are Karaoke Vetoers, whose potent combination of self-seriousness and inflexibility leads them to impose their joyless worldview on all those who are foolish enough to include them in social plans. Desperate to stay within their profoundly narrow comfort zone, Karaoke Vetoers’ obstructive behavior often leaves their acquaintances exasperated and genuinely stupefied as to why the hell the UBYC can’t simply shut up and give a costume party, ’80s dance night, escape room, <span>Rocky Horror Picture Show</span> screening, or Ethiopian food a chance for once in their stiflingly monotonous lives.</p>,
      'With a repressive value system that leaves no room whatsoever for excitement, variety, or spontaneity, UBYCs know exactly which activities they prefer—sensible, productive pursuits that adhere to suffocating standards of respectability and propriety—as well as those they vehemently disapprove of, namely anything else. Tragically unaware of the diversity and depth of delights that one can experience in life, Karaoke Vetoers are insistent that friends, family, coworkers, and those they’ve only just met at a mutual acquaintance’s New Year’s Eve party bend to their constricting terms and conditions.',
      'Despite lacking the ability to persuade others through reason, Karaoke Vetoers often succeed in enforcing their will, as their stubbornness tends to wear down the enthusiasm of those who made the mistake of trying to have a good time in their presence. Above all, whether they’re refusing to join a group photo until the idea is abandoned, making the case for why outdoor movies aren’t actually enjoyable, or conquering Afghanistan in order to impose strict religious rule, Karaoke Vetoers’ driving motivation is an unyielding commitment to ensuring that everyone—absolutely everyone—is as miserable as they are.'
    ],
    careers: [
      'Dietician',
      'MPAA Ratings Board Member',
      'Wahhabi Cleric',
      'Surgeon General',
      'Film Studies Doctoral Candidate'
    ],
    famousList: [
      { name: 'Nancy Reagan' },
      { name: 'Carrie Nation' },
      { name: 'Sam Eagle' },
      { name: 'The Pennsylvania Dutch' },
      { name: 'George Steinbrenner' },
      { name: 'Ayatollah Khomeini' },
      { name: 'The ATF' },
      { name: 'Raw Vegans' },
      { name: 'Nurse Ratched' },
      { name: 'Germany' }
    ],
    famousImages: KaraokeFamous
  },
  UBOC: {
    label: 'The Huge Spider',
    heroImg: Spider,
    bgImg: SpiderBg,
    icon: UBOCIcon,
    color: Colors.yellow,
    acronym: 'Unlovable Barnacle Orgiastic Christ',
    tags: [
      'Unwelcome',
      'Gross',
      'Skittery',
      'Vile'
    ],
    descriptions: [
      'While they are objectively disgusting and detestable individuals, Huge Spiders are also the most misunderstood of all personality types. Despite their strong desire to fraternize with others, UBOCs tend to avoid social situations, which is just fine with everyone else, as The Huge Spider’s generally abhorrent nature and habit of popping up where they are least wanted typically results in onlookers recoiling, feeling physically sickened, or initiating a group email thread with nearby coworkers to privately discuss how repulsive they are. In addition to their complete lack of social graces, Huge Spiders are further distinguished by their unsettling and alien mannerisms, such as slinking unnoticed to the perimeter of group gatherings, eating nothing but a jar of room-temperature sauerkraut for lunch alone at their desk, or digging at their ear, teeth, or nose with a pen they borrowed from a coworker just moments earlier.',
      'While Huge Spiders are commonly perceived as threats to be avoided and are often derided using various nicknames based on what they smell like or how profusely they sweat, the truth is that beneath their revolting exteriors, they are gentle individuals who are simply trying to go about their day without causing people to attack them or leave en masse whenever they enter a room. When confronted with stress, Huge Spiders find comfort retreating to a favorite secluded place, such as a dark corner or the farthest unoccupied school bus seat, where they try to remain as motionless as possible until others have had time to process the trauma inflicted by the UBOC’s presence.',
      'Huge Spiders, however, possess a remarkable ability to persevere in the face of a world that both fears them and wishes to see them crushed and immediately disposed of. As they demonstrate time and time again, UBOCs continue to come back long after everyone has concluded that they’re gone for good, each time launching even more flecks of spittle during conversations, leaving thicker deposits of dandruff wherever they sit, and trimming even greater quantities of beard hair over the office bathroom sink.'
    ],
    careers: [
      'Night Janitor',
      'Pawn Shop Cashier',
      'Rental Car Shuttle Driver',
      'Focus Group Participant',
      'Basketball Court Mopper'
    ],
    famousList: [
      { name: 'Larry Flynt' },
      { name: 'Donkey Kong' },
      { name: 'Nancy Grace' },
      { name: 'The Undertaker' },
      { name: 'Courtney Love' },
      { name: 'Stephen Miller' },
      { name: 'Joey Chestnut' },
      { name: 'Mia Thermopolis' },
      { name: 'Don King' },
      { name: 'Emily Dickinson' }
    ],
    famousImages: SpiderFamous
  },
  LBOA: {
    label: 'The Big Bad Chicken',
    heroImg: Chicken,
    bgImg: ChickenBg,
    icon: LBOAIcon,
    color: Colors.orange,
    acronym: 'Lovable Barnacle Orgiastic Antichrist',
    tags: [
      'Assertive',
      'Compassionless',
      'Crazed',
      'Tyrannical'
    ],
    descriptions: [
      'LBOAs are the kings of the coop—and they know it. Often described as the type of individual you either love or that will have you eliminated for standing in their way, Big Bad Chickens are extremely confident individuals who are unwilling to yield on any point and whom others—be they acquaintances, family members, or fellow drug cartel members—tend to look up to. When a Big Bad Chicken struts into a boardroom, an underground kickboxing ring, a Hells Angels initiation, an International Court of Justice defendant’s box, or an annual dental hygienist conference, others can’t help but respond with stuttering deference or simply by cowering in their big bad chickeny shadow.',
      'While Big Bad Chickens exhibit remarkable control over others through their cool self-assuredness and outsize reputation—a reputation that often precedes them on DIY pyrotechnics message boards or in federal wiretap transcripts—Big Bad Chickens struggle when it comes to controlling themselves. Most notably, Big Bad Chickens are prone to making rash decisions with lasting consequences, such as whether to burn down a conquered village, how best to rig the World Cup hosting ballot in their favor, or which body part to send along with the ransom request.',
      'Unfazed by pleas for mercy or industry best practices, Big Bad Chickens are brazen, dominating presences who impose their will regardless of situation or circumstance, tolerating dissent only from the occasional Bigger, Badder Chicken they might encounter. Given their particular character, it’s no surprise that Big Bad Chickens throughout history have so frequently left their mark as despots, prison riot leaders, Royal Rumble Champions, and multi-level cosmetics marketers.'
    ],
    careers: [
      'Luchador',
      'Demolition Derby Champion',
      'Commander Of Joint Special Operations Command',
      'Runway Model',
      'Philip Morris General Counsel'
    ],
    famousList: [
      { name: 'Pete Rose' },
      { name: 'Catherine The Great' },
      { name: 'Hurricane Andrew' },
      { name: 'Prince' },
      { name: 'Secretariat' },
      { name: 'Ancient Greece' },
      { name: 'Slobodan Milosevic' },
      { name: 'Martha Stewart' },
      { name: 'Joe Camel' },
      { name: 'Julie Andrews' }
    ],
    famousImages: ChickenFamous
  },
  LBYA: {
    label: 'The George H.W. Bush',
    heroImg: George,
    bgImg: GeorgeBg,
    icon: LBYAIcon,
    color: Colors.teal,
    acronym: 'Lovable Barnacle Persnickety Antichrist',
    tags: [
      'Bland',
      'Efficient',
      'Inoffensive',
      'Electable'
    ],
    descriptions: [
      'History is rife with towering figures who, through the sheer strength of their charisma, single-handedly reshape the course of world events—and serving unmemorably between those monumental icons are LBYAs. Reserved and innocuous, those with the George H.W. Bush personality type are natural (if wholly uninspiring) leaders who can be counted on to quietly carry out their duties, even if doing so means making difficult choices, like cutting back on personal expenses or invading Panama. Possessing a nearly robotic level of patience and practicality, LBYAs are extremely reliable, emotionally inert individuals who are always at the ready when anything needs to be long-divided, organized into manila folders, guided through a budget reconciliation process, or cross-checked against a master spreadsheet.',
      'However, underneath a George H.W. Bush’s dreary, mechanical exterior lies a fun-loving side. After completing a day’s work of collating tax documents or asking students to settle down, LBYAs often set aside a strictly regimented period for recreational pursuits, such as planning their dinners for the week ahead, reading a good technical manual, renting a PG-13 movie, performing monthly checks of their home smoke detectors, or quietly ruminating in a sensible armchair.',
      'Often accused of being an “out-of-touch scold” or “the single most boring person anyone has ever encountered in their life,” a George H.W. Bush stands behind the lackluster, overcautious decisions they make, supported by a deep-seated belief system that leaves no room for risk, happiness, or human feeling of any kind. Though they might go their entire lives without receiving validation from their friends, family, or an electorate, George H.W. Bushes are forward-thinking individuals who take comfort in the knowledge that they might eventually be appreciated 50 years after their death.'
    ],
    careers: [
      'Podiatrist',
      'Interim Provost',
      'Cemetery Caretaker',
      'Oatmeal Lobbyist',
      'Chief Of Illinois Bureau Of Weights And Measures'
    ],
    famousList: [
      { name: 'Barbara Bush' },
      { name: 'Lance Ito' },
      { name: 'Siri' },
      { name: 'Connie Chung' },
      { name: 'The Keebler Elves' },
      { name: 'Mitt Romney' },
      { name: 'Wednesday Addams' },
      { name: 'Father Time' },
      { name: 'The FCC' },
      { name: 'Walk Signal Man' }
    ],
    famousImages: GeorgeFamous
  },
  UBYA: {
    label: 'The Scrabble Dictionary Memorizer',
    heroImg: Scrabble,
    bgImg: ScrabbleBg,
    icon: UBYAIcon,
    color: Colors.orange,
    acronym: 'Unlovable Barnacle Persnickety Antichrist',
    tags: [
      'Knowledgeable',
      'Strident',
      'Nitpicking',
      'Unbearable'
    ],
    descriptions: [
      'Possessing a remarkable ability to recall obscure regulations that’s matched only by their remarkable inability to let a single thing slide, Scrabble Dictionary Memorizers are highly and infuriatingly detail-oriented individuals. They are easily identified by a conspicuous lack of natural human traits, including empathy, lenience, politeness, warmth, and the ability to compromise, making them simultaneously one of the most financially successful and socially reviled of all personality types. With Scrabble Dictionary Memorizers, what’s important isn’t that their personal interactions are harmonious and enjoyable, but that they are able to publicly demonstrate their knowledge of technicalities, which they can then leverage to gain an advantage over others, be it in a friendly family game, professional presentation, public comment portion of a city council meeting, or condo board hearing on permissible lawn decorations.',
      'Regardless of whether they’re citing a little known rule to give themselves a leg up or to deny someone else a workplace injury claim, for the UBYA, what matters most is that there are defined guidelines in place and that the UBYA is aware of them while others are not. As such, Scrabble Dictionary Memorizers possess a near total immunity to the vast majority of social cues, ranging from the uncomfortable silences that result every time they speak, to a direct request that they just shut the hell up already.',
      <p>Compared to the general population, UBYAs are far more likely to seek positions of authority that allow them to lecture others on proper rules of conduct and administer punishments. This tendency often begins with a childhood spent volunteering to be the referee in informal sporting activities rather than a playing participant and causes them to find their calling later in life as newspaper copy editors, IRS auditors, PTA members, <span>Jeopardy!</span> hosts, or constitutional originalists.</p>
    ],
    careers: [
      'Equestrian Judge',
      'Medical Claims Reviewer',
      'Air Traffic Controller',
      'Dorm RA',
      'Grand Inquisitor'
    ],
    famousList: [
      { name: 'IBM’s Watson' },
      { name: 'Rachel Maddow' },
      { name: 'Ho Chi Minh' },
      { name: 'The Lorax' },
      { name: 'Ken Starr' },
      { name: 'Bert' },
      { name: 'Eleanor Roosevelt' },
      { name: 'Roomba' },
      { name: 'The Book Of Leviticus' },
      { name: 'National Public Radio' }
    ],
    famousImages: ScrabbleFamous
  },
  UBOA: {
    label: 'The Worst One',
    heroImg: Worst,
    bgImg: WorstBg,
    icon: UBOAIcon,
    color: Colors.green,
    shareIdent: 'The',
    acronym: 'Unlovable Barnacle Orgiastic Antichrist',
    tags: [
      'Defective',
      'Awful',
      'Lowly',
      'Worthless'
    ],
    descriptions: [
      'Of all the personality types, this is unquestionably The Worst One. In terms of overall temperament, work habits, emotional intelligence, self-awareness, ability to cope with stress, and all other aspects of human experience, individuals with this personality type are irreparably dysfunctional on every level. Known for being deaf to social cues, incapable of maintaining healthy relationships, and totally inept at introspection, UBOAs are uniquely terrible in every way that a personality can be measured.',
      'Enjoyable in neither small nor large gatherings, The Worst Ones have long since been driven out of every social circle they have ever inhabited, due equally to their chronic unreliability and their failure to master many of the most basic requirements of human social functioning, such as self-control, comprehensible communication, and passable hygiene. Even worse romantic partners than they are friends, UBOAs also tend to be overwhelmed by a sense of sexual inadequacy while simultaneously being too dishonest and generally unpleasant to be deserving even of pity.',
      'Given their countless glaring weaknesses, it’s difficult to believe that the UBOA could be unaware of how inferior they are as a member of the human species, prompting the question of why such a person would even take a personality test in the first place, as they surely must know in advance how bleak and hideous the result will be. To spare the rest of the world from being infected by their presence, The Worst Ones are best suited to an isolated life at sea, cryogenic biosuspension experimentation, or, at the very least, a freelance position where assignments can be completed entirely over email.'
    ],
    careers: [
      'Vice President Of Native Content',
      'Dog Fighting Trainer',
      'Private Equity Asset Manager',
      'YouTube Teen Influencer Agent',
      'New York Yankees Luxury Box Sales Rep'
    ],
    famousList: [
      { name: 'Ivanka Trump' },
      { name: 'Regis Philbin' },
      { name: 'Flo' },
      { name: 'Sean Penn' },
      { name: 'Milo Yiannopoulos' },
      { name: 'John Wilkes Booth' },
      { name: 'Bratz Dolls' },
      { name: 'Logan Paul' },
      { name: 'Mariah Carey' },
      { name: 'Daffy Duck' }
    ],
    famousImages: WorstFamous
  },
  LPOA: {
    label: 'The Murderous Former Starlet',
    heroImg: Murderous,
    bgImg: MurderousBg,
    icon: LPOAIcon,
    color: Colors.yellow,
    acronym: 'Lovable Parasite Orgiastic Antichrist',
    tags: [
      'Glamorous',
      'Volatile',
      'Vengeful',
      'Deranged'
    ],
    descriptions: [
      'Well-liked, engaging, and unhinged to the point of requiring institutionalization, Murderous Former Starlets are talented performers who conceal their many hostile impulses beneath a veneer of charm and charisma. More prone to overthinking than other personality types, LPOAs typically spend every waking moment stewing over their steadily declining social standing or reliving memories of times long ago when they went 8 for 10 shooting in a home playoff game against Oakwood High, were asked to model for multiple friends’ photography class projects, delivered several well-received punchlines at a large social gathering, or skillfully helmed New York City during the worst terrorist attack in American history.',
      'Silently tormented by feelings of inadequacy and self-hatred, Murderous Former Starlets tend to struggle in stressful situations, be they career troubles, realizing that the server attending to their table is much younger and more attractive than they are, or the unavoidable fact that their election to prom court was over a dozen years ago. LPOAs typically react strongly to such stressors, often directing their savage temper at the nearest available barista, stagehand, Congressional aide, or opposing cable news talking head. Passionate and strong-willed, Murderous Former Starlets are just as likely to raise a glass to give a celebratory toast as they are to smash it against a wall in rage and hold the shards up to the neck of a former lover or a service worker who mispronounced their name while shouting, “Look! Look what you’ve made of me!”',
      'Given the immense strain of maintaining the outward appearance of relevance, the Murderous Former Starlet is much likelier than others to turn to painkillers, hair bleach, hair plugs, spectacularly unwarranted political campaigns, leather clothing, entering their children into toddler beauty pageants, a lengthy series of face- and neck-lifts, or extended theater residencies in Branson, Missouri in a desperate attempt to convince themselves they’re still the same person who was, for one brief and thrilling moment, actually admired by those around them.'
    ],
    careers: [
      'Cosmetics Sales Rep',
      'Perennial Presidential Candidate',
      'Bridal Consultant',
      'Nudity-Based Performance Artist',
      'Little League Coach'
    ],
    famousList: [
      { name: 'Newt Gingrich' },
      { name: 'Lance Armstrong' },
      { name: 'The United States Of America' },
      { name: 'The Red M&M' },
      { name: 'Blac Chyna' },
      { name: 'Yahoo!' },
      { name: 'Tammy Faye Bakker' },
      { name: 'The Duggars' },
      { name: 'The Old Man From Up' },
      { name: 'Bat Boy' }
    ],
    famousImages: MurderousFamous
  },
  LPYA: {
    label: 'The Soot-Caked Ragamuffin',
    heroImg: Soot,
    bgImg: SootBg,
    icon: LPYAIcon,
    color: Colors.beige,
    acronym: 'Lovable Parasite Persnickety Antichrist',
    tags: [
      'Weary',
      'Bedraggled',
      'Sad',
      'Putrid '
    ],
    descriptions: [
      'While all personality types have their weaknesses, The Soot-Caked Ragamuffin is unique for having by far the most. Commonly found eating alone in the rear booth of a diner, silently spinning around on a tire swing across the recess yard from everyone else, or actively cowering, those with the LPYA personality type tend to exist on the outermost social fringes due to their extreme meekness and disreputable nature.',
      'Feeble, skittish, and likelier than not to be coated in filth, the LPYA would likely elicit sympathy for their overall wretched presence if only they didn’t first elicit disgust and nausea. Soot-Caked Ragamuffins often find it difficult to maintain relationships with anyone other than their fellow Soot-Caked Ragamuffins, as no other personality type would ever be willing to sacrifice social standing by interacting with someone who lacks any idea how to engage in a conversation that doesn’t center around their various allergies, favored fantasy card game, pet snake, or the robot warriors they like to draw in their notebook.',
      'However, despite their lack of any particular talents or prospects for the future, Soot-Caked Ragamuffins maintain an admirable level of determination that helps them eke their way through life. Indeed, although such instances are rare, LPYAs do occasionally succeed in obtaining the meagerest scraps of what others take for granted—be it an entry-level job or a single date with a fellow human being—which instills them with just enough hope to struggle through another demeaning day. While they’re most often seen by others as useless, heartbreaking, or untreatably diseased, LPYAs know deep down that they are scrappy fighters who will make the best of the few miserable cards they were dealt or die alone in their bare studio apartments trying.'
    ],
    careers: [
      'Busboy',
      'Off-Track Bettor',
      'Adjunct Professor',
      'Washington Generals Point Guard',
      'Airport Masseuse'
    ],
    famousList: [
      { name: 'Tonya Harding' },
      { name: 'Sylvia Plath' },
      { name: 'The Cleveland Browns' },
      { name: 'Slimer' },
      { name: 'Barry Goldwater' },
      { name: 'Detroit' },
      { name: 'Randy Quaid' },
      { name: 'Sears' },
      { name: 'Frankenstein’s Monster' },
      { name: 'Suri Cruise' }
    ],
    famousImages: RagamuffinFamous
  },
  UPYA: {
    label: 'Comcast',
    heroImg: Comcast,
    bgImg: ComcastBg,
    icon: UPYAIcon,
    color: Colors.teal,
    acronym: 'Unlovable Parasite Persnickety Antichrist',
    tags: [
      'Domineering',
      'Selfish',
      'Greedy',
      'Inescapable'
    ],
    descriptions: [
      <p>Those with the Comcast personality type tend to be high-status individuals who are most often described by friends and family as oppressive, incompetent, and bloodsucking. UPYAs are quite rare in the context of the overall world population, which is a significant relief to the millions of individuals who live in constant dread of interacting with one. Whether listening to them tell their department to “work smarter, not harder,” hearing them explain why they’re returning only half the security deposit, or taking their stage direction during a community theater production of <span>Godspell</span>, even the briefest conversation with a Comcast leaves the average person trembling with rage and dreaming up vivid fantasies of committing a vengeful act of arson.'</p>,
      'For a Comcast, it’s simply their way or the highway, no matter how many people block them on Twitter or petition for a recall election. Despite their abysmal work ethic and lack of any observable talent or skills, Comcasts are often exceptionally high earners who wield a considerable amount of power, owing to a distorted sense of morality that values personal gain far higher than fairness, integrity, OSHA workplace regulations, the natural bonds of affection between parent and child, accepted norms of traffic flow, or any desire to be viewed as a kind and decent human being.',
      'From emailing an urgent work request to a colleague at 7 p.m. on a Saturday to using up the last of a roommate’s laundry detergent with no warning or acknowledgment, UPYAs are always looking to improve their own individual circumstances without any concern for whom they’re inconveniencing or how many times they’re silently flipped off along the way. Given that it’s nearly impossible to cut ties with them once a personal connection has been formed, they tend to slowly bleed every last bit of joy and humanity from the people around them, leading others to the bleak and inevitable realization that death will be their only escape from a Comcast.'
    ],
    careers: [
      'Secret Police',
      'Spam Caller',
      'NFL Commissioner',
      'Student Loan Collector',
      'Soviet Premier'
    ],
    famousList: [
      { name: 'Attila The Hun' },
      { name: 'Bowser' },
      { name: 'Time Warner Cable' },
      { name: 'Leona Helmsley' },
      { name: 'God' },
      { name: 'Bernie Madoff' },
      { name: 'Shub-Niggurath' },
      { name: 'Congress' },
      { name: 'HAL 9000' },
      { name: 'UConn Women’s Basketball Team' }
    ],
    famousImages: ComcastFamous
  },
  UPOA: {
    label: 'The Pervert Landlord',
    heroImg: Landlord,
    bgImg: LandlordBg,
    icon: UPOAIcon,
    color: Colors.orange,
    acronym: 'Unlovable Parasite Orgiastic Antichrist',
    tags: [
      'Observant',
      'Hovering',
      'Heavy-Breathing',
      'Sleazy'
    ],
    descriptions: [
      'UPOAs are known for having the most welcoming nature of all personality types, especially toward those at least 10 to 15 years younger than themselves and of the opposite sex. Driven more by passion than reason, Pervert Landlords do not typically concern themselves with matters they consider frivolous, such as HR policies or their own personal hygiene, instead concentrating their efforts on building personal and professional relationships—and then repeatedly stretching the boundaries of those relationships through suggestive remarks and ambiguously worded requests that deliberately contain both mundane and utterly obscene interpretations.',
      'Hesitant to draw much attention to themselves, UPOAs generally prefer to stay out of the limelight, often repeatedly passing by a yoga studio’s street-facing window instead of going in and joining, or viewing a college field hockey game from an idling hatchback parked next to the field instead of from the bleachers. In social situations, Pervert Landlords are equally happy to lurk around either small or large groups. In one-on-one settings, however, they become much more engaged, often far too much so, as evidenced by their disconcertingly unwavering gaze and the way they punctuate their many probing questions with a shallow, wheezy chuckle.',
      'While Pervert Landlords possess many exceptional traits (like the ability to recall in disturbing detail an outfit worn by a coworker four days prior, or the stamina to maintain a silent, leering crouch posture for hours on end), the characteristic that most distinguishes a Pervert Landlord is their extremely relaxed attitude toward personal privacy. Typically, this means sharing highly risqué jokes with someone they’ve just met, insisting that they’re a hugger during greetings and goodbyes, or using a general statement about fashion or exercise as an opportunity to ask what kind of underwear someone prefers or make an unsolicited offer to help them stretch.'
    ],
    careers: [
      'School Picture Day Photographer',
      'Shoe Fit Specialist',
      'Photo-Based Social App Founder',
      'Fluffer',
      'Stand-Up Comedian'
    ],
    famousList: [
      { name: 'Steven Seagal' },
      { name: 'Mary Kay Letourneau' },
      { name: 'Ursula' },
      { name: 'Andy Dick' },
      { name: 'L. Ron Hubbard' },
      { name: 'Wario' },
      { name: 'Ted Cruz' },
      { name: 'Elizabeth II, Queen Of England' },
      { name: 'Jabba The Hutt' },
      { name: 'Dr. Phil' }
    ],
    famousImages: LandlordFamous
  },
  LPOC: {
    label: 'The Nude Man At The Bottom Of A Manhole',
    heroImg: Manhole,
    bgImg: ManholeBg,
    icon: LPOCIcon,
    color: Colors.green,
    acronym: 'Lovable Parasite Orgiastic Christ',
    tags: [
      'Daring',
      'Shortsighted',
      'Impulsive',
      'Naked '
    ],
    descriptions: [
      'Among the most cheerful and gregarious of all personality types, The Nude Man At The Bottom Of A Manhole is notable for bringing equal amounts of joy and grave concern to those around them. Driven by a zest for life and a desire to make others smile, the LPOC is always willing to go to extreme, dignity-depleting lengths in pursuit of fun, a tendency that frequently results in them becoming helplessly ensnared in situations that are usually quite dangerous, almost always bizarre, and, more often than not, shockingly perverse.',
      'Whatever severely underdeveloped sense of good judgement a Nude Man At The Bottom Of A Manhole possesses is often overpowered by their need to be the life of the party. This is why, by the end of nearly any fraternity house function, workplace holiday party, or ordinary Thursday night, it’s not unusual to find an LPOC drenched in their own vomit; with one or both of their eyebrows singed off; tumbling off a sloped roof; beset by deer ticks; fast asleep in a restaurant dumpster; attempting to extract an object irretrievably lodged in a body cavity; or with their upper body tightly wedged into a drainage pipe, kicking wildly as an animal licks at their unclothed legs and buttocks.',
      'A Nude Man At The Bottom Of A Manhole tends to have many casual acquaintances but few close friends, as few individuals can bear the strain of witnessing the LPOC’s desperate antics inevitably deteriorate from amusing to troubling to best resolved by an arriving team of EMTs. However, in spite of the frequent personal degradations and urgent care co-pays they bring upon themselves, LPOCs are typically very content individuals, secure in their belief that the social validation they receive before their high jinks turn from entertaining to viscerally disturbing is completely worth all the misdemeanor fines, shattered tailbones, and lifetime bans from aquariums.'
    ],
    careers: [
      'Drug Mule',
      'Tequila Brand Ambassador',
      'EDM Festival Hype Man',
      'Medical Guinea Pig',
      'Human Cannonball'
    ],
    famousList: [
      { name: 'Keith Moon' },
      { name: 'Mrs. Doubtfire' },
      { name: 'Twitter' },
      { name: 'Lisa “Left Eye” Lopes' },
      { name: 'Ross Perot' },
      { name: 'Survivor Cast, Seasons 1–21' },
      { name: 'NeNe Leakes' },
      { name: 'Timothy Leary' },
      { name: 'Bulbasaur' },
      { name: 'Brett Kavanaugh' }
    ],
    famousImages: NudeFamous
  },
  LPYC: {
    label: 'The Drama Club Treasurer',
    heroImg: Drama,
    bgImg: DramaBg,
    icon: LPYCIcon,
    color: Colors.yellow,
    acronym: 'Lovable Parasite Persnickety Christ',
    tags: [
      'Orderly',
      'Peppy',
      'Exhausting',
      'Too Much'
    ],
    descriptions: [
      'LPYCs are among the most puzzling of all personality types: extroverted but awkward, lively but boring, outwardly helpful but incapable of engaging in any interpersonal activity without draining every last sliver of enjoyment from it. Despite registering in the lowest percentile on measures of likability, popularity, humor, and general tolerability, Drama Club Treasurers frequently occupy a spot near the center of professional and social groups, owing to their unusual fondness for taking on roles that are too dull and tedious for anyone else to desire, such as designated driver, sports team equipment manager, accommodations booker for an upcoming guys weekend, or Secretary of Agriculture.',
      'Drama Club Treasurers seek validation through social engagement, a unique challenge for those whose tiringly perky demeanor triggers an instantaneous flight response in every person they encounter. Of all personality types, Drama Club Treasurers are the ones whose acquaintances must generate the greatest number of excuses for not attending social functions, lest they be forced to endure a conversation about the elaborate homemade Helga Hufflepuff costume the LPYC wore to work last Halloween or how hot Indianapolis’ contemporary dance scene is right now.',
      'Unable to experience the enjoyable feeling of inclusion that results from being seen as a full and respected member of a social body, Drama Club Treasurers are therefore forced to settle for the next best sensation they can achieve: others’ grudging recognition of their effort. Typically, LPYCs seek to attain this feeble sense of belonging by going far beyond what is required of them on even the smallest tasks, whether that means sending out an office-wide questionnaire in September about possible holiday party dates, responding to an acquaintance’s innocent question about what to do in Paris with an eight-page color-coordinated spreadsheet, or single-handedly doing all the work for their ninth-grade group science project in hopes of being invited to hang out later. Compared to other personality types, Drama Club Treasurers are by far the most likely to run for public office, although their palpable struggle with attempting to exhibit something resembling a laid-back, relatable personality will usually cause them to be passed over in favor of a less qualified but more human-seeming opponent.'
    ],
    careers: [
      'Alumni Outreach Director',
      'Seasonal Store Owner',
      'Ball Boy/Girl',
      'Team-Building Exercise Coordinator',
      'House Minority Whip'
    ],
    famousList: [
      { name: 'Lorne Michaels' },
      { name: 'Ann Curry' },
      { name: 'The Holy Spirit' },
      { name: 'Optimus Prime' },
      { name: 'Clippy' },
      { name: 'Condoleezza Rice' },
      { name: 'Land O’Lakes Maiden' },
      { name: 'Ken Burns' },
      { name: 'Confucius' },
      { name: 'Dave Matthews Band' }
    ],
    famousImages: DramaFamous
  },
  UPYC: {
    label: 'The Birthday Ruiner',
    heroImg: Birthday,
    bgImg: BirthdayBg,
    icon: UPYCIcon,
    color: Colors.beige,
    acronym: 'Unlovable Parasite Persnickety Christ',
    tags: [
      'Inconsiderate',
      'Meddlesome',
      'Inflammatory',
      'Boozy'
    ],
    descriptions: [
      'UPYCs are a diverse group, ranging from inconsiderate social outcasts with no observable emotional intelligence, to toxic instigators who feed off the hostility spread by their own secretive manipulations, to drunken buffoons who take any night on the town as an invitation to perform an impromptu raspberry-vodka-fueled mock striptease next to their Buffalo Wild Wings booth. However, what all UPYCs have in common is an astounding ability to spark conflict within both tight-knit and ad hoc social groups, meaning that they’re always just one event invitation away from dragging innocent friends, colleagues, fellow karaoke patrons, wedding officiants, or catering staffs into a raging cyclone of thrown drinks and public crying jags.',
      'Birthday Ruiners are notable for their unique ability to turn even the most casual of outings into social disasters that will not be remembered for any enjoyable moments or inside jokes, but rather for the uncomfortable silence during the unexpectedly early Lyft ride home or for the sheer volume of vomit they produced. Whether through intentional spitefulness or pure ignorance of their own destructive behavior, UPYCs are irresistibly drawn to all manner of festivities, where they typically undermine the group’s cohesion by loudly listing past sexual partners that fellow party members have in common, inviting along several of their former Sigma Nu brothers without asking anyone’s permission, or leveling slurred accusations of sluttiness at pretty much all in attendance.',
      'Compared to other personality types, Birthday Ruiners are by far the most likely to be forever branded a persona non grata and to have a contact list full of people who have vowed never to speak to them again. However, UPYCs’ outgoing nature and willingness to share scandalous details about any mutual acquaintances means that even after they have been disinvited from future holiday parties, post-work drinks, baby showers, Vegas weekends, apple picking outings, 300-person destination weddings, or Bible study groups, they will continue causing petty, permanently scarring chaos for many years to come.'
    ],
    careers: [
      'Bravo Host',
      'Shot Girl',
      'Astrologist',
      'Self-Employed Essential Oils Sales Rep',
      'Lifestyle Blogger'
    ],
    famousList: [
      { name: 'Pandora' },
      { name: 'Kim Jong-il' },
      { name: 'Lindsay Lohan' },
      { name: 'The Cat In The Hat' },
      { name: 'Lady Macbeth' },
      { name: 'Jim Jones' },
      { name: 'Mt. Vesuvius' },
      { name: 'Josef Mengele' },
      { name: 'Medusa' },
      { name: 'Ayn Rand' }
    ],
    famousImages: BirthdayFamous
  },
  UPOC: {
    label: 'The 9-Headed Cave Beast',
    heroImg: Beast,
    bgImg: BeastBg,
    icon: UPOCIcon,
    color: Colors.teal,
    acronym: 'Unlovable Parasite Orgiastic Christ',
    tags: [
      'Self-Confident',
      'Appalling',
      'Sadistic',
      'Unholy'
    ],
    descriptions: [
      'The 9-Headed Cave Beast is one of the rarest personality types, as just 3 percent of the population is so accursed. Generally speaking, UPOCs are less daydreamers or big-picture thinkers than they are pitiless monsters seemingly conjured from the blackest recesses of the human soul to despoil all that is good and pure in the world. The 9-Headed Cave Beast is typically an outspoken and assertive individual who doesn’t let any obstacle or cease-fire agreement stand between them and their goals. However, what they are best known for is seeking out the light of life and joy that burns in every human heart and, for their own sickening amusement, snuffing that light from existence.',
      'More disease than human, UPOCs feel like their best selves when they’re inflicting unending despair on others, typically in dynamic social environments, such as Thanksgiving dinners, ethnic cleansing operations, open-plan offices, online comments sections, tech conference panels, or Q and A sessions following a stage play. While invitations to social functions are consequently infrequent, on those occasions when UPOCs find themselves surrounded by unsuspecting prey, they tend to exhibit a variety of behaviors designed to cause maximum suffering in their victims, from greeting acquaintances with a purple nurple, to baselessly implicating hundreds as secret Communists, to unleashing a flight-long monologue about the virtues of becoming an Herbalife distributor upon a helpless airplane seatmate.',
      'In certain instances, however, the legendary destructiveness and barbarity of a 9-Headed Cave Beast can work to their professional advantage, particularly in entrepreneurial pursuits such as contract killing or real estate. It is in these settings, where UPOCs can flex their quick decision-making muscles and feast on the mournful whimpering of innocents, that they reach their fullest potential as individuals.'
    ],
    careers: [
      'Horse Euthanizer',
      'Street Canvasser',
      'Doula',
      'Spring Break Promoter',
      'Hun'
    ],
    famousList: [
      { name: 'Tyra Banks' },
      { name: 'Mehmet Oz' },
      { name: 'Mike Krzyzewski' },
      { name: 'Grimace' },
      { name: 'Investment Banking' },
      { name: 'Kevin Federline' },
      { name: 'Ann Coulter' },
      { name: 'Monsanto' },
      { name: 'Macklemore' },
      { name: 'Captain Kangaroo' }
    ],
    famousImages: BeastFamous
  },
  '17th': {
    label: 'The Fabled 17th Personality',
    heroImg: Fabled,
    acronym: null,
    tags: [
      'Mythical',
      'Extraordinary',
      'Enchanting',
      'Unquantifiable'
    ],
    descriptions: [
      'No…no, this can’t be, can it? It seems incredible—impossible, even—but you are The Fabled 17th Personality. My God, the legend is true! Until the results of this assessment were tabulated this very moment, your personality type was only rumored to exist. Indeed, most of those who have spent their lives analyzing personality traits believed you to be a phantom, a fanciful tale handed down in hushed backroom whispers from one generation of personality type classifiers to the next. Yet here you are, the lore made flesh!',
      'You, dear personality-test-taker, were first alluded to on a ziggurat mosaic dating back to Early Bronze Age Mesopotamia in which you are depicted as a decorated warrior displaying your personality type’s innate desire to be the center of attention in front of a throng of nobles and peasants alike. You then seem to vanish from history for several millennia before reappearing on a clay jug inscribed in Ancient Greek with the words “ambitious doer who is energized by big ideas and passionate intellectual debate.” You again reemerge in a manuscript by a medieval Austrian monk who chronicled unconfirmed sightings in Kitzbühel village of an inhabitant who relished the spotlight but who also always went the extra mile for loved ones and never, ever took friendships for granted.',
      'And of course, there were the celebrated diaries uncovered in the 1920s supposedly written by another Fabled 17th Personality that proved to be nothing more than hoaxes from a forger who, unlike you, did not value a straight-shooting communication style and was not at all ideally suited for a career in sales, politics, or the performing arts. Therefore, you are the first verified Fabled 17th Personality ever, so let us all celebrate your preference for hands-on learning and your strong networking skills, as you might very well be the last.'
    ],
    careers: [
      'Messiah',
      'Boy Band Front Man',
      'Pontoon Boat Captain',
      'Lead Project Manager',
      'Dolphin Trainer'
    ],
    famousList: [
      { name: 'Socrates' },
      { name: 'Melissa Joan Hart' },
      { name: 'You' }
    ],
    famousImages: FabledFamous
  }
})
