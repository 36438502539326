import React, { Component } from 'react'

import * as S from './styles'
import MenuIcon from '../../components/MenuIcon/MenuIcon'
import NavLogo from '../../components/NavLogo/NavLogo'

const navItems = [
  {
    label: 'Home',
    url: '/'
  }, {
    label: 'Take the Test',
    url: '/test'
  }, {
    label: 'Personality Types',
    url: '/personalities'
  }, {
    label: 'Our Methodology',
    url: '/methodology'
  }
]

export default class Nav extends Component {
  state = {
    showMenu: false
  }
  renderNavItem = (navItem) => {
    return <S.NavItem key={navItem.id} href={navItem.url}>
      { navItem.label }
    </S.NavItem>
  }
  render () {
    return <S.Nav>
      <S.LogoWrap href='/'>
        <NavLogo />
        <S.LogoText>6 Minute Test</S.LogoText>
      </S.LogoWrap>
      <MenuIcon toggleMenu={() => { this.setState({ showMenu: !this.state.showMenu }) }} open={this.state.showMenu} />
      { this.state.showMenu &&
        <S.NavMenu>
          <S.NavItems>
            { Object.values(navItems).map((navItem, index) => {
              navItem.id = index
              return this.renderNavItem(navItem)
            }) }
            <S.StyledDivider />
            <S.Social>
              <a href='https://twitter.com/6minutetest' target='_blank'>
                <S.StyledTwitter />
              </a>
              <a href='http://instagram.com/6minutetest' target='_blank'>
                <S.StyledInstagram />
              </a>
            </S.Social>
          </S.NavItems>
        </S.NavMenu> }
    </S.Nav>
  }
}
