import Famous1Img from '../../../assets/images/pngs/famous/06-GeorgeHW/01-BarbraBusy-06.png'
import Famous2Img from '../../../assets/images/pngs/famous/06-GeorgeHW/02-LanceIto-06.png'
import Famous3Img from '../../../assets/images/pngs/famous/06-GeorgeHW/03-Siri-06.png'
import Famous4Img from '../../../assets/images/pngs/famous/06-GeorgeHW/04-ConnieChung-06.png'
import Famous5Img from '../../../assets/images/pngs/famous/06-GeorgeHW/05-KeeblerElves-06.png'
import Famous6Img from '../../../assets/images/pngs/famous/06-GeorgeHW/06-Mitt-Romney-06.png'
import Famous7Img from '../../../assets/images/pngs/famous/06-GeorgeHW/07-Wednesday-Addams-06.png'
import Famous8Img from '../../../assets/images/pngs/famous/06-GeorgeHW/08-Father-Time-06.png'
import Famous9Img from '../../../assets/images/pngs/famous/06-GeorgeHW/09-FCC-06.png'
import Famous10Img from '../../../assets/images/pngs/famous/06-GeorgeHW/10-Walk-Signal-Man-06.png'

export default {
  Famous2: { img: Famous2Img, name: 'Lance Ito' },
  Famous3: { img: Famous3Img, name: 'Siri' },
  Famous4: { img: Famous4Img, name: 'Connie Chung' },
  Famous5: { img: Famous5Img, name: 'The Keebler Elves' },
  Famous6: { img: Famous6Img, name: 'Mitt Romney' },
  Famous7: { img: Famous7Img, name: 'Wednesday Addams' },
  Famous8: { img: Famous8Img, name: 'Father Time' },
  Famous9: { img: Famous9Img, name: 'The FCC' },
  Famous10: { img: Famous10Img, name: 'Walk Signal Man' },
  Famous1: { img: Famous1Img, name: 'Barbara Bush' }
}
