import styled, { css } from 'styled-components'

import { Colors, Breakpoints, Type } from 'app/styles'
import { Submit, Check } from 'app/ui-kit/Icons'

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: auto;
  overflow: hidden;
  background: ${Colors.navy};

  @media(max-width: ${Breakpoints.main}) {
    justify-content: center;
    flex-direction: column;
  }
`

export const FooterContent = styled.div`
  ${Type.SmallBody};
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px 80px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  color: ${Colors.white};

  @media(max-width: ${Breakpoints.main}) {
    max-width: 400px;
    padding: 15px 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
`

export const Disclaimer = styled.span`
  @media(max-width: ${Breakpoints.main}) {
    margin-top: 8px;
  }
`

export const Copywrite = styled.span`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  line-height: 1em;

  span {
    &:not(:first-child) {
      font-size: 10px;
    }

    &:first-child {
      margin-bottom: 3px;
    }
  }

  a {
    color: ${Colors.white};

    &:hover {
      color: ${Colors.orange};
    }
  }

  @media(max-width: ${Breakpoints.main}) {
    margin: 0 auto;
    margin-bottom: 5px;
    align-items: center;
    text-align: center;
  }
`

export const StyledLink = styled.a`
  font-size: 10px;
  color: ${Colors.white};

  span {
    &:hover {
      opacity: .7;
    }
  }
`

export const StyledTextField = styled.input`
  ${Type.Body};
  -webkit-appearance: none;
  font-size: 12px;
  transition: all 150ms ease-out;
  width: 315px;
  max-width: 315px;
  color: ${Colors.white};
  border: none;
  background: transparent;
  color: ${Colors.white};
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  padding-bottom: 10px;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${Colors.white};
    font-weight: 100;
  }

  ${props => props.success && css`
    max-width: 150px;
  `}
`

export const StyledSubmit = styled(Submit)`
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  right: 5px;
  height: 24px;
  width: 24px;
  transform: scale(1);

  &:hover {
    transform: scale(1.05);
  }
`

export const StyledCheck = styled(Check)`
  cursor: pointer;
  position: absolute;
  bottom: 9px;
  right: 5px;
  height: 15px;
  width: 15px;
  transform: scale(1);

  &:hover {
    transform: scale(1.05);
  }
`

export const StyledMailForm = styled.div`
  position: relative;
`

export const StyledForm = styled.form`
  position: relative;
  border-bottom: 1px solid ${Colors.white};

  @media (max-width: ${Breakpoints.main}) {
    width: 100%
  }
`
