import React, { Component } from 'react'

import * as S from './styles'
import { testimonials } from '../../data/testimonials'
import { Carousel } from 'app/ui-kit'

export default class Testimonials extends Component {
  state = {
    slideIndex: 0
  }
  componentDidMount = () => {}
  render () {
    const { slideIndex } = this.state
    return <S.Testimonials>
      <Carousel slideIndex={slideIndex}>
        { Object.values(testimonials).map((testimonial, index) => {
          const slide = index
          return (
            <S.Testimonial
              className='carousel-cell'
              key={index}
              color={testimonial.type.color}
              active={slideIndex === slide}
              onClick={() => { this.setState({ slideIndex: slide }) }}
            >
              <p>"{ testimonial.text }"</p>
              <strong>{ testimonial.name } - { testimonial.type.name }</strong>
            </S.Testimonial>
          )
        }) }
      </Carousel>
    </S.Testimonials>
  }
}
