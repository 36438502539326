import styled, { css } from 'styled-components'

import { Colors, Breakpoints } from 'app/styles'
import { Facebook, Twitter, Linkedin } from 'app/ui-kit/Icons'

export const SocialShare = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 55px;
  margin-top: 17px;

  @media (max-width: ${Breakpoints.main}) {
    flex-direction: row;
    align-items: center;
    margin-right: 0;
    margin-bottom: 15px;
    margin-top: 0;
  }
`

const sharedIconStyles = css`
  margin-bottom: 10px;

  @media (max-width: ${Breakpoints.main}) {
    margin-bottom: 0;
  }
`

export const StyledFacebook = styled(Facebook)`
  height: 40px;
  width: 35px;
  ${sharedIconStyles};
  fill: ${Colors.teal};
`
export const StyledTwitter = styled(Twitter)`
  height: 40px;
  width: 35px;
  ${sharedIconStyles};
  fill: ${Colors.orange};
`
export const StyledLinkedin = styled(Linkedin)`
  ${sharedIconStyles};
  fill: ${Colors.yellow};
  height: 30px;
  width: 30px;
  margin-left: 8px;

  @media (max-width: ${Breakpoints.main}) {
    margin-left: 0;
    margin-right: 10px;
  }
`
