export const black = '#000000'
export const white = '#FFFFFF'
export const transparent = 'transparent'
export const background = '#FFF7EF'

export const black012 = 'rgba(0, 0, 0, 0.12)'
export const black50 = 'rgba(0, 0, 0, 0.50)'
export const gray = '#979797'

export const navy = '#2D3057'
export const green = '#02955D'
export const greenLight = '#57BB91'
export const teal = '#84C9B7'
export const tealLight = '#AEDDCF'
export const tealDark = '#6DA19A'
export const orange = '#F05A3D'
export const orangeDark = '#B6442F'
export const beige = '#F2B099'
export const beigeLight = '#F7C9BD'
export const yellow = '#FFCA13'
export const yellowLight = '#F7DF9C'
