import React, { Component } from 'react'
import upperFirst from 'lodash/upperFirst'

import * as S from './styles'
import { Colors } from 'app/styles'
import { Button } from 'app/ui-kit'
import { SpectrumTypes } from '../../data/spectrums'

export default class Hero extends Component {
  renderHome = () => {
    let randomNumber1 = Math.floor(Math.random() * (SpectrumTypes.enumValues.length - 1))
    let randomNumber2 = Math.floor(Math.random() * (SpectrumTypes.enumValues.length - 1))

    if (randomNumber2 === randomNumber1) {
      if (randomNumber2 < (SpectrumTypes.enumValues.length - 1)) randomNumber2 = randomNumber2 + 1
      else if (randomNumber2 >= (SpectrumTypes.enumValues.length - 1)) randomNumber2 = randomNumber2 - 1
    }

    return <S.HeroContent>
      <S.HeroCharacter src={SpectrumTypes.enumValues[randomNumber1].img} />
      <S.HeroText>
        <S.Title>You give us <span style={{ color: Colors.green, fontWeight: 'bold' }}>6 minutes.</span></S.Title>
        <S.Title>We’ll give you <span style={{ color: Colors.orange, fontWeight: 'bold' }}>you.</span></S.Title>
        <S.StyledDivider />
        <S.Copy>
          <p>With 16 different personality types, our free personality assessment is as unique as you are. So what are you waiting for? Take the shortcut to self-knowledge now!</p>
          <p>No registration or introspection required.</p>
        </S.Copy>
        <a href='/test'>
          <Button>Take the test</Button>
        </a>
      </S.HeroText>
      <S.HeroCharacter src={SpectrumTypes.enumValues[randomNumber2].img} />
    </S.HeroContent>
  }
  renderSetup = () => {
    return <S.HeroContent>
      <S.HeroText>
        <S.Title>About The Test</S.Title>
        <S.StyledDivider />
        <S.Copy>
          <p>The 6 Minute Test is the world’s fastest, simplest, most accurate, and most personally validating personality assessment tool. In just 36 questions, our test will determine your most prominent traits and most crippling shortcomings, then assign you one of 16 definitive, easy-to-recite labels that’s ready to stand in as your identity from that point forward. Set aside a few minutes and let our algorithm tell you who you truly are.</p>
        </S.Copy>
        <a href='/test'>
          <Button>Start test</Button>
        </a>
      </S.HeroText>
    </S.HeroContent>
  }
  renderPersonalitiesImages = () => {
    return <S.PersonalityImages length={SpectrumTypes.enumValues.length - 2}>
      { SpectrumTypes.enumValues.map((type, index) => {
        return <img src={type.imgSized} key={index} onClick={() => { this.props.personalityClick(type) }} />
      }) }
    </S.PersonalityImages>
  }
  renderPersonalities = () => {
    return <S.HeroContent>
      <S.HeroText>
        <S.Title>The Four Components Of You</S.Title>
        <S.StyledDivider />
        <S.Copy>
          <p>Did you know that everyone, even the blandest and most useless among us, has a personality made up of four components? It’s the differing amounts of these four components that determine who you are, what you value, and what you should have done with your life when you had the chance. Learn more about these four personality spectrums below!</p>
        </S.Copy>
        { this.renderPersonalitiesImages() }
      </S.HeroText>
    </S.HeroContent>
  }
  render404 = () => {
    return <S.HeroContent>
      <S.HeroText>
        <S.Title>Oops!</S.Title>
        <S.StyledDivider />
        <S.Copy>
          <p>It seems the page you requested is not available to someone with your personality type. Try asking a more socially adept friend to navigate the site for you.</p>
        </S.Copy>
      </S.HeroText>
    </S.HeroContent>
  }
  render () {
    const type = upperFirst(this.props.type)
    return <S.Hero flushBottom={this.props.type === 'home'}>
      { this[`render${type}`]() }
    </S.Hero>
  }
}
