import React, { Component } from 'react'

import * as S from './styles'
import { Button } from 'app/ui-kit'

export default class Snackbar extends Component {
  render () {
    const { message, route, cta } = this.props

    return <S.Snackbar>
      <S.Message>
        {message}
      </S.Message>
      <S.Actions>
        <a href={route}>
          <Button skinny>
            {cta}
          </Button>
        </a>
      </S.Actions>
    </S.Snackbar>
  }
}
