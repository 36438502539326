import Famous1Img from '../../../assets/images/pngs/famous/04-HugeSpider/01-LarryFlint-04.png'
import Famous2Img from '../../../assets/images/pngs/famous/04-HugeSpider/02-Donkey-Kong-04.png'
import Famous3Img from '../../../assets/images/pngs/famous/04-HugeSpider/03-NancyGrace-04.png'
import Famous4Img from '../../../assets/images/pngs/famous/04-HugeSpider/04-Undertaker-04.png'
import Famous5Img from '../../../assets/images/pngs/famous/04-HugeSpider/05-CourtneyLove-04.png'
import Famous6Img from '../../../assets/images/pngs/famous/04-HugeSpider/06-StephenMiller-04.png'
import Famous7Img from '../../../assets/images/pngs/famous/04-HugeSpider/07-JoeyChestnut-04.png'
import Famous8Img from '../../../assets/images/pngs/famous/04-HugeSpider/08-MiaThermopolis-04.png'
import Famous9Img from '../../../assets/images/pngs/famous/04-HugeSpider/09-DonKing-04.png'
import Famous10Img from '../../../assets/images/pngs/famous/04-HugeSpider/10-EmilyDickinson-04.png'

export default {
  Famous2: { img: Famous2Img, name: 'Donkey Kong' },
  Famous3: { img: Famous3Img, name: 'Nancy Grace' },
  Famous4: { img: Famous4Img, name: 'The Undertaker' },
  Famous5: { img: Famous5Img, name: 'Courtney Love' },
  Famous6: { img: Famous6Img, name: 'Stephen Miller' },
  Famous7: { img: Famous7Img, name: 'Joey Chestnut' },
  Famous8: { img: Famous8Img, name: 'Mia Thermopolis' },
  Famous9: { img: Famous9Img, name: 'Don King' },
  Famous10: { img: Famous10Img, name: 'Emily Dickinson' },
  Famous1: { img: Famous1Img, name: 'Larry Flynt' }
}
