import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Scroll from 'react-scroll'

import * as S from './styles'
import { SpectrumGroups } from '../../data/spectrums'
import Hero from '../../components/Hero/Hero'
import Footer from '../../components/Footer/Footer'
import Nav from '../../components/Nav/Nav'

let scroll = Scroll.animateScroll

export default class Spectrums extends Component {
  state = {
    scrollY: 0
  }
  spectrumRefs = []
  hero = null
  page = null
  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = (event) => {
    this.setState({ scrollY: window.scrollY, height: window.innerHeight })
  }

  renderSpectrum = (spectrum) => {
    return <S.Spectrum
      key={spectrum.index}
      color={spectrum.color}
      leftAlign={spectrum.index % 2}
      ref={(el) => { this.spectrumRefs[spectrum.name] = el }}>
      <S.Border color={spectrum.colorOpo}>
        <S.Content>
          <S.Name animateOnce animateIn='flipInX' duration={0.5} delay={spectrum.index % 2 ? 550 : 250} color={spectrum.colorOpo}>({ spectrum.acronym }) { spectrum.name }</S.Name>
          <S.StyledScrollAnimation animateOnce animateIn='fadeIn' duration={0.5} delay={950}>
            <strong>if you...</strong>
            <ul>
              { spectrum.traits.map((trait, index) => {
                return <li key={index}>{trait}</li>
              }) }
            </ul>
          </S.StyledScrollAnimation>
        </S.Content>
        <S.StyledImageScroll animateOnce animateIn={spectrum.index % 2 ? 'fadeInLeft' : 'fadeInRight'} delay={spectrum.index % 2 ? 550 : 250}>
          <S.SpectrumImageMobile color={spectrum.colorOpo}><S.SpectrumImage offset={spectrum.avatarOffset || '55%'} src={spectrum.imgSized} /></S.SpectrumImageMobile>
        </S.StyledImageScroll>
      </S.Border>
    </S.Spectrum>
  }
  renderSpectrums = () => {
    return <S.Spectrums>
      { SpectrumGroups.enumValues.map((group, index) => {
        return <S.SpectrumGroup key={index}>
          <S.Title>
            <S.StyledScrollAnimation animateOnce animateIn='fadeIn' duration={0.5}>
              { group.description }
            </S.StyledScrollAnimation>
          </S.Title>
          { group.types.map((spectrum, index) => {
            spectrum.index = index
            return this.renderSpectrum(spectrum)
          }) }
        </S.SpectrumGroup>
      }) }
    </S.Spectrums>
  }
  scrollToPersonality = (personality) => {
    const element = ReactDOM.findDOMNode(this.spectrumRefs[personality.name])
    if (!element) return console.log('no element found')
    const elementRect = element.getBoundingClientRect()
    const absoluteElementTop = (elementRect.top) + window.pageYOffset
    const middle = absoluteElementTop - ((window.innerHeight - elementRect.height) / 2)
    scroll.scrollTo(middle, { duration: '675ms' })
  }
  render () {
    const { scrollY, height } = this.state
    const heroElm = ReactDOM.findDOMNode(this.hero)
    const pageElm = ReactDOM.findDOMNode(this.page)
    let elemHeight
    if (heroElm) elemHeight = heroElm.getBoundingClientRect().height
    let pageHeight
    if (pageElm) pageHeight = pageElm.getBoundingClientRect().height
    return <S.SpectrumsWrap ref={(el) => { this.page = el }}>
      <Nav />
      <Hero ref={(el) => { this.hero = el }} type='personalities' personalityClick={(personality) => { this.scrollToPersonality(personality) }} />
      { this.renderSpectrums() }
      { scrollY >= elemHeight / 2 && <S.StyledBackToTop bottom={scrollY + height >= pageHeight - 40} onClick={() => { scroll.scrollToTop() }} /> }
      <Footer />
    </S.SpectrumsWrap>
  }
}
