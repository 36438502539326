import styled from 'styled-components'

import { Colors, Animations } from 'app/styles'

export const CompleteCircle = styled.div`
  opacity: 0;
  pointer-events: none;
  width: 200vh;
  height: 200vh;
  border-radius: 100%;
  background: ${Colors.beige};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  animation: ${Animations.ColorFillScreen} 475ms forwards ease-in;
  animation-delay: 100ms;
  filter: blur(35px);
`
