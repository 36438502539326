import styled, { css } from 'styled-components'

import { Breakpoints, Colors, Type, Animations } from 'app/styles'
import { Button } from 'app/ui-kit'
import ProgressBar from '../ProgressBar/ProgressBar'
import QuestionInput from '../QuestionInput/QuestionInput'

export const TestTaker = styled.div`
  padding: 25vh 80px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    height: 150px;
    width: 100%;
    background: linear-gradient(${Colors.background}, ${Colors.background}, transparent);
    z-index: 50;
  }

  @media(max-width: ${Breakpoints.main}) {
    justify-content: center;
    flex-direction: column;
    padding: 15vh 35px;

    &:before {
      height: 115px;
    }
  }

  /* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
  /* Portrait and Landscape */
  @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 568px)
    and (-webkit-min-device-pixel-ratio: 2) {
    &:before {
      display: none;
    }
  }
`

export const QuestionNumber = styled.div`
  ${Type.LargeBody};
  font-weight: bold;
  position: relative;
  padding-top: 15vh;
  margin: 0;
  opacity: .5;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 45px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: ${Colors.orange};
    transition: width 275ms ease-out 250ms;
  }

  p {
    margin: 0;
    opacity: 0;
    transform-origin: bottom center;
  }
`

export const StyledQuestion = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  opacity: .1;
  border-radius: 5px;
  transition: opacity 150ms ease-out;
  
  &.active {
    opacity: 1;

    ${QuestionNumber} {
      p {
        animation: ${Animations.RotateIn} 1250ms forwards;
      }
      &:after {
        width: 100%;
      }
    }
  }
`

export const QuestionText = styled.p`
  ${Type.LargeBody};
  margin: 0;
  margin-bottom: 25px;
  max-width: 600px;
`

export const StyledQuestionInput = styled(QuestionInput)`
  padding-bottom: 15vh;
`

export const TestProgress = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: linear-gradient(transparent, ${Colors.background}, ${Colors.background}, ${Colors.background});
  padding: 25px 80px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: ${Colors.black50};

  @media(max-width: ${Breakpoints.main}) {
    padding: 15px 20px;
  }

  /* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
  /* Portrait and Landscape */
  @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 568px)
    and (-webkit-min-device-pixel-ratio: 2) {
    background: ${Colors.transparent};
  }
`

export const Numbers = styled.span`
  font-variant-numeric: tabular-nums;
  letter-spacing: -1px;
  font-weight: bold;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${Breakpoints.main}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Timer = styled.div`
  margin-left: 25px;
`

export const StyledButton = styled(Button)`
  ${props => props.alignLeft && css`
    margin-right: auto;
  `}
`

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;

  ${StyledButton} {
    margin-left: 5px;
  }
`

export const Disclaimer = styled.small`
  margin-right: 10px;
  cursor: pointer;
  user-select: none;

  ${props => props.active && css`
    color: ${Colors.orange};
  `}
`

export const Calculating = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${Colors.background};
`

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledProgressBar = styled(ProgressBar)`
  position: relative;
  margin-left: 15px;

  @media (max-width: ${Breakpoints.main}) {
    margin-top: 5px;
    margin-left: 0;
  }
`

export const Debug = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  bottom: 66px;
  left: 0;
  right: 0;
  background: ${Colors.yellow};
  padding: 25px;

  small {
    margin-right: 15px;
  }

  strong {
    margin: 0 15px;
  }
`

export const DebugItem = styled.div`
  margin: 5px;
  border: 1px solid ${Colors.black012};
  border-radius: 3px;
  padding: 3px 5px;
`
