import styled from 'styled-components'

import { Animations, Type, Colors } from 'app/styles'

export const InternetExplorer = styled.div`
  animation: ${Animations.FadeIn} 1250ms forwards;
  animation-delay: 250ms;
  opacity: 0;
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 50px;
  box-sizing: border-box;
  text-align: center;
`

export const LogoWrap = styled.a`
  position: relative;
  z-index: 100;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`

export const LogoText = styled.span`
  ${Type.LogoBold};
  color: ${Colors.navy};
  margin-left: 10px;
`
