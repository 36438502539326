import styled, { css } from 'styled-components'

import { Type, Colors, Breakpoints, Animations } from 'app/styles'
import { Menu, Divider, Instagram, Twitter } from 'app/ui-kit/Icons'

export const Nav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px;
  box-sizing: border-box;
  z-index: 100;

  @media(max-width: ${Breakpoints.main}) {
    padding: 0 15px;
  }
`

export const NavMenu = styled.div`
  animation: ${Animations.FadeIn} 450ms forwards;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${Colors.teal};
  z-index: 50;
`

export const NavItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 125px);
  max-height: 500px;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
`

export const NavItem = styled.a`
  opacity: 0;
  animation: ${Animations.RotateIn} 450ms forwards;
  transform-origin: bottom center;
  ${Type.Large};
  font-weight: 100;
  cursor: pointer;
  transition: all .2s ease-out;
  display: flex;
  align-items: center;
  color: ${Colors.white};
  user-select: none;

  &:hover {
    color: ${Colors.beigeLight};
  }

  &:nth-child(1n) {
    animation-delay: 250ms;
  }

  &:nth-child(2n) {
    animation-delay: 300ms;
  }

  &:nth-child(3n) {
    animation-delay: 350ms;
  }

  &:nth-child(4n) {
    animation-delay: 400ms;
  }
`

export const LogoWrap = styled.a`
  position: relative;
  z-index: 100;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const LogoText = styled.span`
  ${Type.LogoBold};
  color: ${Colors.navy};
  margin-left: 10px;
`

export const StyledMenu = styled(Menu)`
  cursor: pointer;
  position: relative;
  z-index: 100;
  fill: ${Colors.green};
  margin-left: auto;
  margin-right: 25px;
`

export const StyledDivider = styled(Divider)`
  margin: 15px 0;
  opacity: 0;
  animation: ${Animations.RotateIn} 450ms forwards 500ms;
  transform-origin: bottom center;
`

export const Social = styled.div`
  display: flex;
  flex-direction: row;
  opacity: 0;
  animation: ${Animations.RotateIn} 450ms forwards 500ms;
  transform-origin: bottom center;
`

const sharedSocialIconStyles = css`
  fill: ${Colors.white};
  width: 40px;
  height: 40px;
  margin: 5px;

  &:hover {
    fill: ${Colors.orange};
  }
`

export const StyledInstagram = styled(Instagram)`
  ${sharedSocialIconStyles};
`

export const StyledTwitter = styled(Twitter)`
  ${sharedSocialIconStyles};
`
