import React from 'react'

import Famous1Img from '../../../assets/images/pngs/famous/13-NudeMan/01-KeithMoon-13.png'
import Famous2Img from '../../../assets/images/pngs/famous/13-NudeMan/02-Mrs.Doubtfire-13.png'
import Famous3Img from '../../../assets/images/pngs/famous/13-NudeMan/03-Twitter-13.png'
import Famous4Img from '../../../assets/images/pngs/famous/13-NudeMan/04-Lisa-13.png'
import Famous5Img from '../../../assets/images/pngs/famous/13-NudeMan/05-RossPerot-13.png'
import Famous6Img from '../../../assets/images/pngs/famous/13-NudeMan/06-SurvivorCast-13.png'
import Famous7Img from '../../../assets/images/pngs/famous/13-NudeMan/07-NeneLeaks-13.png'
import Famous8Img from '../../../assets/images/pngs/famous/13-NudeMan/08-TimothyLeary-13.png'
import Famous9Img from '../../../assets/images/pngs/famous/13-NudeMan/09-Bulbasaur-13.png'
import Famous10Img from '../../../assets/images/pngs/famous/13-NudeMan/10-BrettK-13.png'

export default {
  Famous2: { img: Famous2Img, name: 'Mrs. Doubtfire' },
  Famous3: { img: Famous3Img, name: 'Twitter' },
  Famous4: { img: Famous4Img, name: 'Lisa "Left Eye" Lopes' },
  Famous5: { img: Famous5Img, name: 'Ross Perot' },
  Famous6: { img: Famous6Img, name: <p><span>Survivor</span> Cast, Seasons 1–21</p> },
  Famous7: { img: Famous7Img, name: 'NeNe Leakes' },
  Famous8: { img: Famous8Img, name: 'Timothy Leary' },
  Famous9: { img: Famous9Img, name: 'Bulbasaur' },
  Famous10: { img: Famous10Img, name: 'Brett Kavanaugh' },
  Famous1: { img: Famous1Img, name: 'Keith Moon' }
}
