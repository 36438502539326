import Root from '../components/Root/Root'
import Home from '../pages/Home'
import TakeTest from '../pages/TakeTest'
import Personalities from '../pages/Personalities'
import Privacy from '../pages/Privacy'
import InternetExplorer from '../pages/InternetExplorer'
import Personality from '../pages/Personality'
import Results from '../pages/Results'
import Spectrums from '../pages/Spectrums'
import NotFound from '../components/NotFound/NotFound'

export default [{
  component: Root,
  routes: [{
    path: '/',
    exact: true,
    component: Home
  }, {
    path: '/test',
    component: TakeTest
  }, {
    path: '/methodology',
    component: Spectrums
  }, {
    path: '/personalities',
    component: Personalities
  }, {
    path: '/privacy-policy',
    component: Privacy
  }, {
    path: '/ie',
    component: InternetExplorer
  }, {
    path: '/personality/:type',
    component: Personality
  }, {
    path: '/results/:type?',
    component: Results
  }, {
    path: '*',
    component: NotFound
  }]
}]
