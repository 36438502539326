import Famous1Img from '../../../assets/images/pngs/famous/05-BadChicken/01-Pete-Rose-.png'
import Famous2Img from '../../../assets/images/pngs/famous/05-BadChicken/02-Catherine-The-Great-05.png'
import Famous3Img from '../../../assets/images/pngs/famous/05-BadChicken/03-HurricaneAndrew-05.png'
import Famous4Img from '../../../assets/images/pngs/famous/05-BadChicken/04-Prince-05.png'
import Famous5Img from '../../../assets/images/pngs/famous/05-BadChicken/05-Secretariat-05.png'
import Famous6Img from '../../../assets/images/pngs/famous/05-BadChicken/06-Ancient-Greece-05.png'
import Famous7Img from '../../../assets/images/pngs/famous/05-BadChicken/07-SlobodanMilosevic-5.png'
import Famous8Img from '../../../assets/images/pngs/famous/05-BadChicken/08-MarthaStewart-05.png'
import Famous9Img from '../../../assets/images/pngs/famous/05-BadChicken/09-Joe-Camel-05.png'
import Famous10Img from '../../../assets/images/pngs/famous/05-BadChicken/10-JulieAndrews-05.png'

export default {
  Famous2: { img: Famous2Img, name: 'Catherine The Great' },
  Famous3: { img: Famous3Img, name: 'Hurricane Andrew' },
  Famous4: { img: Famous4Img, name: 'Prince' },
  Famous5: { img: Famous5Img, name: 'Secretariat' },
  Famous6: { img: Famous6Img, name: 'Ancient Greece' },
  Famous7: { img: Famous7Img, name: 'Slobodan Milosevic' },
  Famous8: { img: Famous8Img, name: 'Martha Stewart' },
  Famous9: { img: Famous9Img, name: 'Joe Camel' },
  Famous10: { img: Famous10Img, name: 'Julie Andrews' },
  Famous1: { img: Famous1Img, name: 'Pete Rose' }
}
