import { Enum } from 'enumify'
import Antichrist from '../assets/images/pngs/THud_SpectrumCharacters_AntiChrist.png'
import Barnacle from '../assets/images/pngs/THud_SpectrumCharacters_Barnacle.png'
import Christ from '../assets/images/pngs/THud_SpectrumCharacters_Christ.png'
import Lovable from '../assets/images/pngs/THud_SpectrumCharacters_Lovable.png'
import Orgiastic from '../assets/images/pngs/THud_SpectrumCharacters_Orgiastic.png'
import Parasite from '../assets/images/pngs/THud_SpectrumCharacters_Parasite.png'
import Persnickity from '../assets/images/pngs/THud_SpectrumCharacters_Persnickity.png'
import Unlovable from '../assets/images/pngs/THud_SpectrumCharacters_Unlovable.png'
import AntichristSized from '../assets/images/pngs/character-sized/THud_SpectrumCharacters_AntiChrist.png'
import BarnacleSized from '../assets/images/pngs/character-sized/THud_SpectrumCharacters_Barnacle.png'
import ChristSized from '../assets/images/pngs/character-sized/THud_SpectrumCharacters_Christ.png'
import LovableSized from '../assets/images/pngs/character-sized/THud_SpectrumCharacters_Lovable.png'
import OrgiasticSized from '../assets/images/pngs/character-sized/THud_SpectrumCharacters_Orgiastic.png'
import ParasiteSized from '../assets/images/pngs/character-sized/THud_SpectrumCharacters_Parasite.png'
import PersnickitySized from '../assets/images/pngs/character-sized/THud_SpectrumCharacters_Persnickity.png'
import UnlovableSized from '../assets/images/pngs/character-sized/THud_SpectrumCharacters_Unlovable.png'

import { Colors } from 'app/styles'

export class SpectrumTypes extends Enum {}
SpectrumTypes.initEnum({
  LOVABLE: {
    opposite: 'UNLOVABLE',
    traits: [
      'Are worthy of love'
    ],
    acronym: 'L',
    img: Lovable,
    imgSized: LovableSized,
    color: Colors.beige,
    colorOpo: Colors.beigeLight,
    avatarOffset: '50%'
  },
  UNLOVABLE: {
    opposite: 'LOVABLE',
    traits: [
      'Do not deserve to feel or experience adoration in any way',
      'Have been told by multiple significant others, relatives, mental health professionals, and clergy members that no one will ever love you',
      'Are incapable of engendering the faintest sense of affection from even one other organism on Earth',
      'Are unworthy of love'
    ],
    acronym: 'U',
    img: Unlovable,
    imgSized: UnlovableSized,
    color: Colors.beigeLight,
    colorOpo: Colors.beige,
    avatarOffset: '50%'
  },
  BARNACLE: {
    opposite: 'PARASITE',
    traits: [
      'Are a harmless, inert, and utterly immaterial nonentity at social gatherings',
      'Don’t have strong feelings about many things and cannot name a single person who would care if you did',
      'Spend most of your free time nodding blankly at what other people say',
      'Are often not factored in when deciding how many cars a group should take'
    ],
    acronym: 'B',
    img: Barnacle,
    imgSized: BarnacleSized,
    color: Colors.tealDark,
    colorOpo: Colors.tealLight,
    avatarOffset: '60%'
  },
  PARASITE: {
    opposite: 'BARNACLE',
    traits: [
      'Are always there when a friend is in need to capitalize on their suffering',
      'Derive 30 percent or more of your daily caloric intake from sneaking bites of acquaintances’ meals',
      'Choose your social circle based entirely on the likelihood of one day being named in those individuals’ wills',
      'Are currently rooting through someone else’s freezer'
    ],
    acronym: 'P',
    img: Parasite,
    imgSized: ParasiteSized,
    color: Colors.tealLight,
    colorOpo: Colors.tealDark
  },
  PERSNICKETY: {
    opposite: 'ORGIASTIC',
    traits: [
      'Live in utter dread of stimulation',
      'Become aroused at the thought of correcting someone who misuses the word “peruse”',
      'Excitedly await the next opportunity to degrease your range hood filter',
      'Own three or more label makers'
    ],
    acronym: 'Y',
    img: Persnickity,
    imgSized: PersnickitySized,
    color: Colors.yellow,
    colorOpo: Colors.yellowLight
  },
  ORGIASTIC: {
    opposite: 'PERSNICKETY',
    traits: [
      'Are happiest in the midst of highly stimulating environments, such as loud concerts or supermax prison riots',
      'Are always open to trying new things laced with ketamine',
      'Consider hot-dog-eating contests a between-meal snack',
      'Seek sexual stimulation with anyone or anything even remotely compatible with your genitals'
    ],
    acronym: 'O',
    img: Orgiastic,
    imgSized: OrgiasticSized,
    color: Colors.yellowLight,
    colorOpo: Colors.yellow
  },
  CHRIST: {
    opposite: 'ANTICHRIST',
    traits: [
      'Love talking with people, whether close friends or complete strangers',
      'Leave social interactions feeling energized',
      'Are generally seen as friendly, open, and the one true light of the world from whom all may attain everlasting salvation',
      'Solve problems by discussing them aloud with others'
    ],
    acronym: 'C',
    img: Christ,
    imgSized: ChristSized,
    color: Colors.green,
    colorOpo: Colors.greenLight,
    avatarOffset: '60%'
  },
  ANTICHRIST: {
    opposite: 'CHRIST',
    traits: [
      'Generally prefer solitude to socializing',
      'Have a small group of close friends instead of a large social circle',
      'Are often described by others as quiet or reserved',
      'Are composed wholly of shadow and evil, preying on that which is good and pure to feed your insatiable lust for darkness'
    ],
    acronym: 'A',
    img: Antichrist,
    imgSized: AntichristSized,
    color: Colors.greenLight,
    colorOpo: Colors.green
  },
  OTHER: {}
})

export class SpectrumGroups extends Enum {}
SpectrumGroups.initEnum({
  1: {
    types: [SpectrumTypes.LOVABLE, SpectrumTypes.UNLOVABLE],
    description: 'Are you deserving of human companionship and affection?'
  },
  2: {
    types: [SpectrumTypes.BARNACLE, SpectrumTypes.PARASITE],
    description: 'Are you a neutral, inoffensive hanger-on, or do you actively drain the life and resources of those around you?'
  },
  3: {
    types: [SpectrumTypes.PERSNICKETY, SpectrumTypes.ORGIASTIC],
    description: 'Do you engage with the world around you in a cautiously fussy manner that ruins everyone else’s good time, or do you jump into everything with recklessly self-gratifying gusto?'
  },
  4: {
    types: [SpectrumTypes.CHRIST, SpectrumTypes.ANTICHRIST],
    description: 'Are you someone who seeks out social situations, or are you a more private person who’s often preoccupied with your own thoughts and feelings?'
  }
})
