import React, { Component } from 'react'

import * as S from './styles'
import { PersonalityTypes } from '../../data/personalityTypes'
import Footer from '../../components/Footer/Footer'
import Nav from '../../components/Nav/Nav'

export default class Personalities extends Component {
  renderPersonalities = () => {
    return <S.Personalities>
      { PersonalityTypes.enumValues.map((type, index) => {
        if (!type.acronym) return null
        return <S.PersonalityWrap href={`/personality/${type.name}`} color={type.color} key={index}>
          <S.Personality key={index}>
            <S.Avatar color={type.color}>
              <img src={type.icon} />
              <S.Background bgImg={type.bgImg} />
            </S.Avatar>
          </S.Personality>

          <S.Name>
            <strong>{type.name}</strong>
            {type.label}
          </S.Name>
        </S.PersonalityWrap>
      }) }
    </S.Personalities>
  }
  render () {
    return <S.PersonalitiesWrap>
      <Nav />
      { this.renderPersonalities() }
      <Footer />
    </S.PersonalitiesWrap>
  }
}
