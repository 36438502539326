import { css } from 'styled-components'

import { Breakpoints } from 'app/styles'

export const Large = css`
  font-size: 34px;
  font-weight: bold;

  @media (max-width: ${Breakpoints.main}) {
    font-size: 22px;
  }
`

export const Medium = css`
  font-size: 22px;

  @media (max-width: ${Breakpoints.main}) {
    font-size: 17px;
  }
`

export const LogoBold = css`
  font-size: 22px;
  font-weight: bold;
`

export const Title = css`
  font-size: 34px;
  font-weight: bold;
`

export const TitleLight = css`
  font-size: 35px;
  font-weight: 100;
`

export const Body = css`
  font-size: 16px;
`

export const SmallBody = css`
  font-size: 14px;
  line-height: 28px;
  font-weight: 100;
`

export const LargeBody = css`
  font-size: 24px;
  font-weight: 100;

  @media (max-width: ${Breakpoints.small}) {
    font-size: 18px;
  }
`

export const LargeInput = css`
  font-size: 24px;

  @media (max-width: ${Breakpoints.small}) {
    font-size: 18px;
  }
`

export const SmallSecondary = css`
  font-family: quasimoda-2, sans-serif;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
`

export const MediumSecondary = css`
  font-family: quasimoda-2, sans-serif;
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
`

export const LargeSecondary = css`
  font-family: quasimoda-2, sans-serif;
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
`

export const FontFace = 'quasimoda, sans-serif;'
