import styled, { css } from 'styled-components'

import { Type, Breakpoints } from 'app/styles'
import { Divider } from 'app/ui-kit/Icons'

export const Hero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 0;
  box-sizing: border-box;

  ${props => props.flushBottom && css`
    padding-bottom: 0;
  `}

  @media (max-width: ${Breakpoints.main}) {
    padding-top: 120px;
    padding-bottom: 25px;
  }
`

export const HeroContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 960px;
  text-align: center;

  @media (max-width: ${Breakpoints.main}) {
    align-items: flex-start;
    text-align: left;
  }
`

export const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px;
  max-width: 750px;

  @media (max-width: ${Breakpoints.main}) {
    align-items: flex-start;
    padding: 0 20px;
  }
`

export const HeroCharacter = styled.img`
  height: 450px;
  margin: 0 35px;
  flex-shrink: 0;

  @media (max-width: ${Breakpoints.main}) {
    &:first-child {
      display: none;
    }
  }

  @media (max-width: ${Breakpoints.main}) {
    display: none;
  }
`

export const Copy = styled.div`
  margin-bottom: 15px;

  p {
    ${Type.Medium};
    margin: 0;
    margin-bottom: 15px;
  }
`

export const Title = styled.h1`
  ${Type.Large};
  margin: 0;
  padding: 0;
`

export const StyledDivider = styled(Divider)`
  margin: 15px 0;
`

export const PersonalityImages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
  margin-top: 25px;

  img {
    width: calc(100% / 8);
    transition: all 150ms ease-out;
    transform-origin: bottom center;
  }

  &:hover {
    img {
      cursor: pointer;
      filter: grayscale(1);

      &:hover {
        filter: grayscale(0);
      }
    }
  }
`
