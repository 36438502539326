import styled, { css } from 'styled-components'

import { Type, Animations } from 'app/styles'
import { Divider } from 'app/ui-kit/Icons'
import { Button } from 'app/ui-kit'

export const StyledDivider = styled(Divider)`
  margin: 15px 0;
`

export const Intro = styled.div`  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 50vh;
  max-width: 600px;
  opacity: 1;
  overflow: hidden;
  margin-right: auto;

  ${props => props.hide && css`
    animation: ${Animations.FadeOutHide} 850ms ease-out forwards;
  `}
`

export const Copy = styled.div`
  p {
    ${Type.Body};
    margin: 0;
    margin-bottom: 15px;
  }
`

export const Title = styled.h4`
  ${Type.LargeBody};
  font-weight: bold;
  margin: 0;
`

export const StyledButton = styled(Button)`
  margin-top: 15px;
`
