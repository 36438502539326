import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import * as S from './styles'
import Nav from '../../components/Nav/Nav'
import TestTaker from '../../components/TestTaker/TestTaker'

@withRouter
export default class TakeTest extends Component {
  render () {
    return <S.Taketest>
      <Nav />
      <TestTaker />
    </S.Taketest>
  }
}
