import React from 'react'

export default (props) =>
  <svg {...props} width='145px' height='31px' viewBox='0 0 145 31'>
    <g id='Wireframes' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Divider' transform='translate(72.500000, 15.000000) scale(1, -1) translate(-72.500000, -15.000000) translate(0.000000, -1.000000)'>
        <path d='M33.6055056,7.15177222 C33.6055056,16.42905 26.0797,23.9477722 16.8024222,23.9477722 C7.52561667,23.9477722 -0.000188888889,16.42905 -0.000188888889,7.15177222 L33.6055056,7.15177222 Z' id='Fill-3' fill='#FFCA13' />
        <polygon id='Fill-8' fill='#F2B099' points='75.0797897 31.0000484 101.669669 19.2525016 101.669669 0.102735938 75.0797897 11.8502828' />
        <path d='M66,15.9997616 C66,22.6273567 60.6273567,28 54.0002384,28 C47.3726433,28 42,22.6273567 42,15.9997616 C42,9.37264329 47.3726433,4 54.0002384,4 C60.6273567,4 66,9.37264329 66,15.9997616' id='Fill-1' fill='#F05A3D' />
        <path d='M145,24 C145,14.6100427 137.385785,7 128,7 C118.613737,7 111,14.6100427 111,24 L145,24 Z' id='Fill-6' fill='#02955D' />
      </g>
    </g>
  </svg>
