import styled, { css } from 'styled-components'

import { Colors, Breakpoints } from 'app/styles'

export const Disclaimer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 80px;
  transform: translate(0, 100%) scale(.9);
  transition: all 250ms ease-out;
  opacity: 0;
  transform-origin: center bottom;

  ${props => props.visible && css`
    bottom: 80px;
    transform: translate(0, 0%) scale(1);
    opacity: 1;

    @media (max-width: ${Breakpoints.main}) {
      top: 50%;
      bottom: auto;
      transform: translate(0, -50%);
      padding: 0 10px;
    }
  `}
`

export const DisclaimerText = styled.div`
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  background: ${Colors.tealLight};
  padding: 20px 25px;
  border-radius: 3px;
`

export const StyledClose = styled.div`
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: 5px;
  font-size: 24px;
  opacity: .5;
  padding: 5px;

  &:hover {
    opacity: .7;
  }
`
