import { initializeFirebase } from '../initializeFirebase'
initializeFirebase()

export default class FirebaseModel {
  static set store (value) {
    this._reduxStore = value
  }

  static get state () {
    return this._reduxStore.getState()
  }

  static get dispatch () {
    return this._reduxStore.dispatch
  }

  static docsById = (docs) => {
    let payload = {}
    docs.forEach(doc => {
      Object.assign(payload, { [doc.id]: doc.data() })
    })
    return payload
  }

  static firestore = async ({ method, normalizer, verb, prefix, storeKey }) => {
    const type = `${prefix}_${verb}_REQUEST`
    FirebaseModel.dispatch({ type, storeKey })

    try {
      const callback = (docs) => {
        const payload = normalizer ? normalizer(docs) : docs
        const type = `${prefix}_${verb}_SUCCESS`
        FirebaseModel.dispatch({ type, payload, storeKey })
      }
      const firebaseData = await method.then ? method.then(callback) : method(callback)
      return firebaseData
    } catch (error) {
      const type = `${prefix}_${verb}_FAILURE`
      FirebaseModel.dispatch({ type, payload: error, storeKey })
    }
  }

  static database = async ({ method, normalizer, verb, prefix, storeKey }) => {
    const type = `${prefix}_${verb}_REQUEST`
    FirebaseModel.dispatch({ type, storeKey })

    try {
      const callback = (snap) => {
        const payload = normalizer ? normalizer(snap) : snap
        const type = `${prefix}_${verb}_SUCCESS`
        FirebaseModel.dispatch({ type, payload, storeKey })
      }
      const firebaseData = await method.then ? method.then(callback) : method(callback)
      return firebaseData
    } catch (error) {
      const type = `${prefix}_${verb}_FAILURE`
      FirebaseModel.dispatch({ type, payload: error, storeKey })
    }
  }
}
