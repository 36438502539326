import React, { Component } from 'react'

import * as S from './styles'
import { Colors } from 'app/styles'

export default class NavLogo extends Component {
  state = {
    randomLogo: null
  }
  componentWillMount () {
    const RandomColors = [Colors.yellow, Colors.orange, Colors.beige]
    const randomNumber = Math.floor(Math.random() * (RandomColors.length - 1))
    const randomColor = RandomColors[randomNumber]
    const Logos = [
      <S.StyledLogo1 color={randomColor} />,
      <S.StyledLogo2 color={randomColor} />,
      <S.StyledLogo3 color={randomColor} />,
      <S.StyledLogo4 color={randomColor} />,
      <S.StyledLogo5 color={randomColor} />
    ]
    this.setState({ randomLogo: Logos[Math.floor(Math.random() * (Logos.length - 1))] })
  }
  render () {
    return <S.Wrap>
      { this.state.randomLogo }
    </S.Wrap>
  }
}
