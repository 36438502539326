import styled, { css } from 'styled-components'

import { Colors, Breakpoints } from 'app/styles'

export const ProgressBar = styled.div`
  position: relative;
  width: 250px;
  height: 8px;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${Colors.black012};

  &:after {
    content: '';
    height: 100%;
    background-color: ${Colors.teal};
    position: absolute;
    top: 0;
    left: 0;
    transition: width 275ms ease-out;
    border-radius: 10px;
  }

  @media (max-width: ${Breakpoints.main}) {
    width: 100%;
  }

  ${props => props.progress && css`
    &:after {
      width: ${props => props.progress};
    }
  `}
`
