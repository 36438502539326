import React, { Component } from 'react'

import * as S from './styles'

export default class MenuIcon extends Component {
  render () {
    return <S.MenuIcon onClick={this.props.toggleMenu} open={this.props.open}>
      <span />
      <span />
      <span />
      <span />
    </S.MenuIcon>
  }
}
