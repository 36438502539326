import React, { Component } from 'react'

import * as S from './styles'

export default class TestIntro extends Component {
  state = {
    startingTest: false
  }
  componentDidMount () {
    const elm = this.refs.introAnim
    elm.addEventListener('animationend', (e) => {
      if (e.srcElement === elm) this.props.onComplete()
    })
  }
  componentWillUnmount () {
    const elm = this.refs.introAnim
    elm.removeEventListener('animationend', (e) => {
      if (e.srcElement === elm) this.props.onComplete()
    })
  }
  render () {
    return <S.Intro hide={this.state.startingTest} ref='introAnim'>
      <S.Title>The 6 Minute Personality Test is the fastest, simplest, and most personally gratifying personality assessment tool available.</S.Title>
      <S.StyledDivider />
      <S.Copy>
        <p>In just 36 questions, our test will determine your most prominent traits and most crippling shortcomings, and then assign you one of 16 definitive, easy-to-recite labels that’s ready to stand in as your identity from that point forward.</p>
      </S.Copy>
      <S.StyledButton onClick={() => { this.setState({ startingTest: true }) }}>Start test</S.StyledButton>
    </S.Intro>
  }
}
