import Famous1Img from '../../../assets/images/pngs/famous/03-Karaoke/01-NancyReagan-03.png'
import Famous2Img from '../../../assets/images/pngs/famous/03-Karaoke/02-Carrie-Nation-03.png'
import Famous3Img from '../../../assets/images/pngs/famous/03-Karaoke/03-SamEagle-03.png'
import Famous4Img from '../../../assets/images/pngs/famous/03-Karaoke/04-Penn-Dutch-03.png'
import Famous5Img from '../../../assets/images/pngs/famous/03-Karaoke/05-GeorgeSteinbrenner-03.png'
import Famous6Img from '../../../assets/images/pngs/famous/03-Karaoke/06-AyatollahKhomeini-03.png'
import Famous7Img from '../../../assets/images/pngs/famous/03-Karaoke/07-ATF-03.png'
import Famous8Img from '../../../assets/images/pngs/famous/03-Karaoke/08-RawVegans-03.png'
import Famous9Img from '../../../assets/images/pngs/famous/03-Karaoke/09-NurseRatched-03.png'
import Famous10Img from '../../../assets/images/pngs/famous/03-Karaoke/19-Germany-03.png'

export default {
  Famous2: { img: Famous2Img, name: 'Carrie Nation' },
  Famous3: { img: Famous3Img, name: 'Sam Eagle' },
  Famous4: { img: Famous4Img, name: 'The Pennsylvania Dutch' },
  Famous5: { img: Famous5Img, name: 'George Steinbrenner' },
  Famous6: { img: Famous6Img, name: 'Ayatollah Khomeini' },
  Famous7: { img: Famous7Img, name: 'The ATF' },
  Famous8: { img: Famous8Img, name: 'Raw Vegans' },
  Famous9: { img: Famous9Img, name: 'Nurse Ratched' },
  Famous10: { img: Famous10Img, name: 'Germany' },
  Famous1: { img: Famous1Img, name: 'Nancy Reagan' }
}
