import Famous1Img from '../../../assets/images/pngs/famous/01-Child_Jumbotron/01-JoyBehar-01.png'
import Famous2Img from '../../../assets/images/pngs/famous/01-Child_Jumbotron/02-BillyMays-01.png'
import Famous3Img from '../../../assets/images/pngs/famous/01-Child_Jumbotron/03-TrixRabbit-01.png'
import Famous4Img from '../../../assets/images/pngs/famous/01-Child_Jumbotron/07-JimmyFallon-01.png'
import Famous5Img from '../../../assets/images/pngs/famous/01-Child_Jumbotron/06-Dog-01.png.png'
import Famous6Img from '../../../assets/images/pngs/famous/01-Child_Jumbotron/08-Housewives-01.png.png'
import Famous7Img from '../../../assets/images/pngs/famous/01-Child_Jumbotron/04-Mask-01.png'
import Famous8Img from '../../../assets/images/pngs/famous/01-Child_Jumbotron/05-Baby-01.png'
import Famous9Img from '../../../assets/images/pngs/famous/01-Child_Jumbotron/09-AnneHathaway-01.png'
import Famous10Img from '../../../assets/images/pngs/famous/01-Child_Jumbotron/10-ImprovComedians-01.png'

export default {
  Jumbo2: { img: Famous2Img, name: 'Billy Mays' },
  Jumbo3: { img: Famous3Img, name: 'The Trix Rabbit' },
  Jumbo4: { img: Famous4Img, name: 'Jimmy Fallon' },
  Jumbo5: { img: Famous5Img, name: 'Dogs' },
  Jumbo6: { img: Famous6Img, name: 'The Real Housewives' },
  Jumbo7: { img: Famous7Img, name: 'The Mask' },
  Jumbo8: { img: Famous8Img, name: 'Baby New Year' },
  Jumbo9: { img: Famous9Img, name: 'Anne Hathaway' },
  Jumbo10: { img: Famous10Img, name: 'Improv Comedians' },
  Jumbo1: { img: Famous1Img, name: 'Joy Behar' }
}
