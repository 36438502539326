import React, { Component } from 'react'

import * as S from './styles'

const answerValues = ['-3', '-2', '-1', '1', '2', '3']

export default class QuestionInput extends Component {
  render () {
    return <S.QuestionInput className={this.props.className}>
      <small>Disagree</small>
      <S.Selections>
        { answerValues.map((value) => {
          return <S.StyledSelection
            key={value}
            value={value}
            onClick={() => { this.props.onSelect(value) }}
            selected={this.props.selected === value}
          />
        }) }
      </S.Selections>
      <small>Agree</small>
    </S.QuestionInput>
  }
}
