import Famous1Img from '../../../assets/images/pngs/famous/10-Ragamuffin/01-Tonyaharding-10.png'
import Famous2Img from '../../../assets/images/pngs/famous/10-Ragamuffin/02-SylviaPlath-10.png'
import Famous3Img from '../../../assets/images/pngs/famous/10-Ragamuffin/03-clevelandbrowns-10.png'
import Famous4Img from '../../../assets/images/pngs/famous/10-Ragamuffin/04-Slimer-10.png'
import Famous5Img from '../../../assets/images/pngs/famous/10-Ragamuffin/05-BarryGoldwater-10.png'
import Famous6Img from '../../../assets/images/pngs/famous/10-Ragamuffin/06-Detroit-10.png'
import Famous7Img from '../../../assets/images/pngs/famous/10-Ragamuffin/07-RandyQuaid-10.png'
import Famous8Img from '../../../assets/images/pngs/famous/10-Ragamuffin/08-Sears-10.png'
import Famous9Img from '../../../assets/images/pngs/famous/10-Ragamuffin/09-Frankenstein-10.png'
import Famous10Img from '../../../assets/images/pngs/famous/10-Ragamuffin/10-Gary_Coleman-10.png'

export default {
  Famous2: { img: Famous2Img, name: 'Sylvia Plath' },
  Famous3: { img: Famous3Img, name: 'The Cleveland Browns' },
  Famous4: { img: Famous4Img, name: 'Slimer' },
  Famous5: { img: Famous5Img, name: 'Barry Goldwater' },
  Famous6: { img: Famous6Img, name: 'Detroit' },
  Famous7: { img: Famous7Img, name: 'Randy Quaid' },
  Famous8: { img: Famous8Img, name: 'Sears' },
  Famous9: { img: Famous9Img, name: "Frankenstein's Monster" },
  Famous10: { img: Famous10Img, name: 'Gary Coleman' },
  Famous1: { img: Famous1Img, name: 'Tonya Harding' }
}
