import Famous1Img from '../../../assets/images/pngs/famous/07-Scrabble/01-IBMWatson-07.png'
import Famous2Img from '../../../assets/images/pngs/famous/07-Scrabble/02-Rachel-Maddow-07.png'
import Famous3Img from '../../../assets/images/pngs/famous/07-Scrabble/03-HoChiMinh-07.png'
import Famous4Img from '../../../assets/images/pngs/famous/07-Scrabble/04-Lorax-07.png'
import Famous5Img from '../../../assets/images/pngs/famous/07-Scrabble/05-Ken-Starr-07.png'
import Famous6Img from '../../../assets/images/pngs/famous/07-Scrabble/06-Bert-07.png'
import Famous7Img from '../../../assets/images/pngs/famous/07-Scrabble/07-EleanorRoosevelt-07.png'
import Famous8Img from '../../../assets/images/pngs/famous/07-Scrabble/08-roomba-07.png'
import Famous9Img from '../../../assets/images/pngs/famous/07-Scrabble/09-Leviticus-07.png'
import Famous10Img from '../../../assets/images/pngs/famous/07-Scrabble/10-NPR-07.png'

export default {
  Famous2: { img: Famous2Img, name: 'Rachel Maddow' },
  Famous3: { img: Famous3Img, name: 'Ho Chi Minh' },
  Famous4: { img: Famous4Img, name: 'The Lorax' },
  Famous5: { img: Famous5Img, name: 'Ken Starr' },
  Famous6: { img: Famous6Img, name: 'Bert' },
  Famous7: { img: Famous7Img, name: 'Eleanor Roosevelt' },
  Famous8: { img: Famous8Img, name: 'Roomba' },
  Famous9: { img: Famous9Img, name: 'The Book Of Leviticus' },
  Famous10: { img: Famous10Img, name: 'National Public Radio' },
  Famous1: { img: Famous1Img, name: 'IBM Watson' }
}
