import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import * as S from './styles'
import Footer from '../../components/Footer/Footer'
import Nav from '../../components/Nav/Nav'
import { PersonalityTypes } from '../../data/personalityTypes'
import PersonalityInfo from '../../components/PersonalityInfo/PersonalityInfo'
import { Snackbar } from 'app/ui-kit'
import { getCompleted, testMath } from '../../data/actions'
import { questions } from '../../data/questions'

const mapStateToProps = (state) => {
  const { answers } = state.app
  return { answers }
}

@withRouter
@connect(mapStateToProps, null)
export default class Results extends Component {
  renderHelmet = () => {
    const { match } = this.props
    const personalityType = match.params.type
    const personality = PersonalityTypes[personalityType]
    const url = 'https://sixminutetest.com'
    const description = `I just found out that I'm ${personality.shareIdent || 'a'} ${personality.label.replace('The ', '')}! Which of the 16 personality types are you? #6MinuteTest ${url}`

    return <Helmet
      meta={[
        { name: 'description', content: description },
        { property: 'og:description', content: description },
        { property: 'og:image', content: personality.heroImg },
        { property: 'twitter:description', content: description },
        { property: 'twitter:image', content: personality.heroImg }
      ]}
    />
  }
  renderSnackbar = () => {
    const { answers, match } = this.props
    const personalityType = match.params.type
    const completed = getCompleted(answers)
    const results = testMath(answers)
    const testComplete = completed.length === questions.length
    let resultsType
    if (results) resultsType = results.personalityType

    let options = {}
    if (!testComplete) {
      options.message = 'Your test is incomplete, these are not your results.'
      options.cta = 'Take Test'
      options.route = '/test'
    } else if (resultsType !== personalityType) {
      options.message = 'How did you get here? These are not your results.'
      options.cta = 'View Results'
      options.route = `/results/${resultsType}`
    }

    if (options.message) {
      return <Snackbar
        {...options}
      />
    }
  }
  render () {
    return <S.Results>
      { this.renderHelmet() }
      <Nav />
      { this.renderSnackbar() }
      <PersonalityInfo />
      <Footer />
    </S.Results>
  }
}
