import styled from 'styled-components'

import { Logo1, Logo2, Logo3, Logo4, Logo5 } from 'app/ui-kit/Icons'

export const Wrap = styled.div`
  display: flex;
`

export const StyledLogo1 = styled(Logo1)`
  fill: ${props => props.color};
`

export const StyledLogo2 = styled(Logo2)`
  fill: ${props => props.color};
`

export const StyledLogo3 = styled(Logo3)`
  fill: ${props => props.color};
`

export const StyledLogo4 = styled(Logo4)`
  fill: ${props => props.color};
`

export const StyledLogo5 = styled(Logo5)`
  fill: ${props => props.color};
`
