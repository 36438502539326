import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import Scroll from 'react-scroll'

import * as S from './styles'
import { questions } from '../../data/questions'
import { Loading } from 'app/ui-kit'
import { testMath, getCompleted, sortTypes } from '../../data/actions'
import { updateTest } from '../../store/app/actions'
import TestIntro from '../../components/TestIntro/TestIntro'
import { TestModel } from 'app/firebase-models'
import Disclaimer from '../../components/Disclaimer/Disclaimer'
import CompleteAnimation from '../../components/CompleteAnimation/CompleteAnimation'

const resultsLoadingCopy = [
  'One moment please',
  'Detecting lies',
  'Identifying weaknesses',
  'Untangling neuroses',
  'Tabulating perversions',
  'Quantifying essence'
]

let scroll = Scroll.animateScroll
let Events = Scroll.Events

const mapStateToProps = (state) => {
  const { answers, updated } = state.app
  return { answers, updated }
}

const dispatchProps = { updateTest }

@withRouter
@connect(mapStateToProps, dispatchProps)
export default class TestTaker extends Component {
  state = {
    activeQuestion: 1,
    loading: true,
    answers: [],
    updated: null,
    calculating: false,
    testStarted: false,
    showDisclaimer: false
  }

  questionRefs = []

  componentDidMount = () => {
    const { answers } = this.props
    this.findActiveElement()
    if (!answers) scroll.scrollToTop()
    window.addEventListener('scroll', this.eventListeners)
    window.addEventListener('resize', this.eventListeners)
  }
  componentWillUnmount = () => {
    Events.scrollEvent.remove('begin')
    Events.scrollEvent.remove('end')
  }
  componentDidUpdate = (prevProps) => {
    const { answers } = this.props
    if (!answers && this.state.activeQuestion !== 1) {
      return this.setState({ activeQuestion: 1 }, () => {
        this.scrollToActiveQuestion()
      })
    } else if (prevProps.answers !== answers) {
      let i
      for (i = 0; i <= Object.keys(answers).length; i++) {
        if (!answers[i] || i === Object.keys(answers).length) {
          let nextQuestionIndex = i + 1
          if (nextQuestionIndex > questions.length) nextQuestionIndex = questions.length
          return this.setState({ activeQuestion: nextQuestionIndex }, () => {
            this.scrollToActiveQuestion()
          })
        }
      }
    }
  }
  setUserAnswer = (questionIndex, answer) => {
    const { answers } = this.props
    let newAnswers = Object.assign({}, answers)
    newAnswers[questionIndex] = answer

    this.props.updateTest({ answers: newAnswers })
  }
  renderCalculating = () => {
    const randomNumber = Math.floor(Math.random() * (Object.keys(resultsLoadingCopy).length - 1)) + 1

    return <S.Calculating>
      <S.Message>
        <Loading />
        <span>{resultsLoadingCopy[randomNumber]}...</span>
      </S.Message>
    </S.Calculating>
  }
  renderQuestions = () => {
    const { answers } = this.props
    const { calculating } = this.state
    if (calculating) return null

    return Object.values(questions).map((question, index) => {
      const questionNumber = index + 1
      return <S.StyledQuestion key={index} ref={(el) => { this.questionRefs[questionNumber] = el }}>
        <S.QuestionNumber>
          <p>{index + 1}</p>
        </S.QuestionNumber>
        <S.QuestionText>{question.text}</S.QuestionText>
        <S.StyledQuestionInput
          onSelect={(value) => { this.setUserAnswer(index, value) }}
          selected={answers && answers[index]}
        />
      </S.StyledQuestion>
    })
  }
  renderDebugger = () => {
    const { answers, updated } = this.props
    const results = testMath(answers)
    if (!results) return null
    const types = results.types
    const personalityType = results.personalityType

    const sortedTypes = sortTypes(types)

    return <S.Debug>
      <small>debugger:</small>
      { sortedTypes.map((type, index) => {
        if (type.typeChar) return <S.DebugItem key={index}>{type.typeChar} : {type.value}</S.DebugItem>
      }) }

      <strong>Personality:</strong>{ personalityType }
      <strong>Updated:</strong>{ updated }
    </S.Debug>
  }
  renderTestProgress = () => {
    const { answers } = this.props
    const { showDisclaimer } = this.state
    const completed = getCompleted(answers)
    const testComplete = completed.length === questions.length
    let completedLength = completed.length
    if (completedLength.toString().length === 1) completedLength = '0' + completedLength

    return <S.TestProgress>
      <S.Numbers>
        { testComplete
          ? 'Complete'
          : `${completedLength} of ${questions.length}` }
        <S.StyledProgressBar complete={completed.length} total={questions.length} />
      </S.Numbers>
      { completed && <S.Actions>
        <S.Disclaimer onClick={() => { this.setState({ showDisclaimer: !showDisclaimer }) }} active={showDisclaimer}>IMPORTANT</S.Disclaimer>
        <S.StyledButton gray onClick={this.resetTest} disabled={!answers}>
          Reset
        </S.StyledButton>
        <S.StyledButton
          secondary
          disabled={!testComplete}
          onClick={this.submitTest}>
            Submit
          { testComplete && <CompleteAnimation /> }
        </S.StyledButton>
      </S.Actions> }
    </S.TestProgress>
  }
  resetTest = () => {
    this.props.updateTest({ answers: null })
    this.startTest()
  }
  scrollToActiveQuestion = () => {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const element = ReactDOM.findDOMNode(this.questionRefs[this.state.activeQuestion])
      if (!element) return console.log('no element found')
      const elementRect = element.getBoundingClientRect()
      const absoluteElementTop = (elementRect.top) + window.pageYOffset
      const middle = absoluteElementTop - ((window.innerHeight - elementRect.height) / 2)
      const questionWrap = ReactDOM.findDOMNode(this.questionWrap)
      if (questionWrap) scroll.scrollTo(middle, { duration: '375ms' })
    }, 100)
  }
  eventListeners = (e) => {
    this.findActiveElement(e)
  }
  firstItem = (found) => {
    found.map((item, index) => {
      if (!item) return
      if (index + 1 !== found.length) item.classList.remove('active')
    })
  }
  findActiveElement = (e) => {
    let viewportHeight = window.innerHeight
    let elements = []

    Object.keys(questions).map(key => {
      const questionNumber = parseInt(key) + 1
      const node = ReactDOM.findDOMNode(this.questionRefs[questionNumber])
      elements.push(node)
    })

    let found = []

    if (e && e.type === 'resize') viewportHeight = window.innerHeight

    elements.map((element, index) => {
      const questionNumber = index + 1
      let elm = element
      if (!elm) return null
      let pos = elm.getBoundingClientRect()
      let topPerc = pos.top / viewportHeight * 100
      let bottomPerc = pos.bottom / viewportHeight * 100
      let middle = (topPerc + bottomPerc) / 2
      let inViewport = middle > 25 && middle < 75

      elm.classList.toggle('active', inViewport)

      if (inViewport) found.push(elm)
      if (questionNumber === elements.length) {
        this.firstItem(found)
      }
    })
  }
  startTest = () => {
    this.setState({ testStarted: true }, () => {
      this.findActiveElement()
      this.scrollToActiveQuestion()
    })
  }
  submitTest = () => {
    const { answers } = this.props
    const completed = getCompleted(answers)
    const testComplete = completed.length === questions.length

    if (!testComplete) return null

    const results = testMath(answers)
    const personalityType = results.personalityType

    this.setState({ calculating: true }, () => {
      TestModel.submit(personalityType)
        .then(() => {
          setTimeout(() => { window.location.href = `/results/${personalityType}` }, 3000)
        })
    })
  }
  render () {
    const { answers } = this.props
    const { calculating, testStarted, showDisclaimer } = this.state

    return <S.TestTaker ref={(el) => { this.questionWrap = el }}>
      { !answers && !testStarted && <TestIntro onComplete={this.startTest} /> }
      { calculating
        ? this.renderCalculating()
        : this.renderQuestions() }
      { false && this.renderDebugger() }
      { this.renderTestProgress() }
      <Disclaimer visible={showDisclaimer} close={() => { this.setState({ showDisclaimer: false }) }} />
    </S.TestTaker>
  }
}
