import styled, { css } from 'styled-components'

import { Carousel } from 'app/ui-kit'
import { Breakpoints, Colors, Type } from 'app/styles'
import { Divider } from 'app/ui-kit/Icons'

export const PersonalityInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export const CenteredTitle = styled.p`
  display: block;
  margin-bottom: 25px;
  text-align: center;
  ${Type.LargeBody};
  font-weight: 700;
`

export const Title = styled.h2`
  ${Type.LargeSecondary};
  margin: 0;
`

export const SubTitle = styled.h4`
  ${Type.LargeBody};
  margin: 0;
`

export const HeroImg = styled.div`
  height: 60vh;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 50px;

  ${props => props.bgImg && css`
    background: url(${props.bgImg}) no-repeat center top;
    background-size: cover;
  `}
`

export const InfoContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 25px;
  box-sizing: border-box;
  justify-content: center;

  @media (max-width: ${Breakpoints.main}) {
    flex-direction: column;
  }
`

export const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 750px;
  width: 100%;
`

export const Tags = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 5px;
`

export const Tag = styled.div`
  font-size: 18px;
  margin-right: 15px;

  &:last-of-type {
    margin-right: 0;
  }
`

export const StyledDivider = styled(Divider)`
  margin: 25px 0;
`

export const Description = styled.div`
  margin-bottom: 25px;
`

export const Paragraph = styled.p`
  margin: 0;
  margin-bottom: 35px;

  span {
    font-style: italic;
  }
`

export const Careers = styled.ul`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 45px;

  @media (max-width: ${Breakpoints.main}) {
    padding: 0;
  }
`

export const Career = styled.li`
  position: relative;
  border-radius: 3px;
  width: 50%;
  margin-bottom: 5px;
  list-style: none;

  &::before {
    content: '';
    position: absolute;
    top: 9px;
    left: -13px;
    height: 7px;
    width: 7px;
    border-radius: 5px;
    overflow: hidden;
    background: ${Colors.teal};
  }

  &:last-of-type {
    margin-right: 0;
  }

  @media (max-width: ${Breakpoints.main}) {
    box-sizing: border-box;
    padding-left: 13px;

    &::before {
      left: -3px;
    }
  }
`

export const ImgWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-radius: 100%;
  flex-shrink: 0;
  border: 5px solid ${Colors.navy};
  box-sizing: border-box;
  overflow: hidden;

  img {
    width: 100%;
  }
`

export const Famous = styled(Carousel)`
  width: 100%;
  .slide {
    &:nth-child(1n) {
      ${ImgWrap} { border-color: ${Colors.yellow}; }
    }

    &:nth-child(2n) {
      ${ImgWrap} { border-color: ${Colors.beige}; }
    }

    &:nth-child(3n) {
      ${ImgWrap} { border-color: ${Colors.teal}; }
    }

    &:nth-of-type(4n) {
      ${ImgWrap} { border-color: ${Colors.orange}; }
    }

    &:nth-of-type(5n) {
      ${ImgWrap} { border-color: ${Colors.green}; }
    }
  }
`

export const Person = styled.div`
  width: calc((100% / ${props => props.count}) - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  text-align: center;
  margin: 0 10px;

  span {
    font-style: italic;
  }

  &:focus {
    outline: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  @media (max-width: ${Breakpoints.main}) {
    width: 100%;
  }
`

export const PersonImage = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: 100%;
`
