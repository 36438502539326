import React, { Component } from 'react'
import * as S from './styles'

const isClient = typeof window !== 'undefined'

export default class Carousel extends Component {
  state = {
    Flickity: null
  }

  componentDidMount () {
    if (isClient) {
      this.init()
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.slideIndex !== this.props.slideIndex) {
      this.flkty.select(this.props.slideIndex)
    }
  }

  init () {
    const Flickity = require('flickity')
    const carousel = this.refs.carousel
    const options = {
      accessibility: true,
      adaptiveHeight: false,
      autoPlay: false,
      cellAlign: 'center',
      cellSelector: undefined,
      contain: false,
      draggable: '>1',
      dragThreshold: 3,
      freeScroll: false,
      friction: 0.2,
      groupCells: false,
      initialIndex: this.props.slideIndex,
      lazyLoad: true,
      percentPosition: true,
      prevNextButtons: true,
      pageDots: false,
      resize: true,
      rightToLeft: false,
      setGallerySize: true,
      watchCSS: false,
      wrapAround: true
    }
    this.flkty = new Flickity(carousel, options)
    this.setState({ Flickity: this.flkty })
  }

  componentWillUnmount () {
    if (this.flkty) {
      this.flkty.destroy()
    }
  }

  render () {
    const { Flickity } = this.state
    const { children, disabled, className } = this.props

    return <S.Carousel
      ref='carousel'
      className={`carousel ${className}`}
      visible={Flickity}
      disabled={disabled}
    >
      { children }
    </S.Carousel>
  }
}
