import { SpectrumTypes } from './spectrums'

export const questions = [
  {
    text: 'You have no idea whether you enjoy being the center of attention because such a thing has never happened.',
    type: SpectrumTypes.BARNACLE,
    tiebreaker: true
  }, {
    text: 'In general, people can look you in the face without recoiling.',
    type: SpectrumTypes.LOVABLE
  }, {
    text: 'Being right matters more to you in a social interaction than being even remotely tolerable.',
    type: SpectrumTypes.PERSNICKETY
  }, {
    text: 'The majority of your actions are cardinal sins.',
    type: SpectrumTypes.ORGIASTIC
  }, {
    text: 'People waiting in line to use restrooms are fair game for small talk.',
    type: SpectrumTypes.CHRIST
  }, {
    text: 'Your parents are comfortable acknowledging their relation to you.',
    type: SpectrumTypes.LOVABLE
  }, {
    text: 'You are 5’4” or taller.',
    type: SpectrumTypes.OTHER
  }, {
    text: 'You are uncomfortable making eye contact with people in your wedding party.',
    type: SpectrumTypes.ANTICHRIST
  }, {
    text: 'The majority of your calendar events are reminders to move your car on street cleaning days.',
    type: SpectrumTypes.BARNACLE
  }, {
    text: 'You are willing to stay out past 8:45 p.m. if it’s shaping up to be an epic night.',
    type: SpectrumTypes.PERSNICKETY,
    tiebreaker: true
  }, {
    text: 'If someone does not respond to a text you sent them, you believe they made the right decision.',
    type: SpectrumTypes.UNLOVABLE
  }, {
    text: 'Your life has been an unending series of disappointments comical to everyone but you.',
    type: SpectrumTypes.UNLOVABLE,
    tiebreaker: true
  }, {
    text: 'You consider “alone time” a disease.',
    type: SpectrumTypes.CHRIST,
    tiebreaker: true
  }, {
    text: 'You can still fit into your high school pants.',
    type: SpectrumTypes.OTHER
  }, {
    text: 'The scariest part about dying is the idea of being surrounded by friends and family.',
    type: SpectrumTypes.ANTICHRIST
  }, {
    text: 'You are usually good at sensing when others are in distress, and it nourishes you.',
    type: SpectrumTypes.PARASITE
  }, {
    text: 'People know the party has started when you arrive because you are continuously announcing this fact.',
    type: SpectrumTypes.CHRIST
  }, {
    text: 'You are in the background of all your baby photos.',
    type: SpectrumTypes.UNLOVABLE
  }, {
    text: 'Cashiers regularly look you in the eye and tell you the transaction was an honor for them.',
    type: SpectrumTypes.LOVABLE
  }, {
    text: 'At hotels, you take the little soaps, shampoos, towels, pillows, TVs, and bed frames.',
    type: SpectrumTypes.PARASITE
  }, {
    text: 'You enjoy a good orgasm when it comes about, but you do not seek them out.',
    type: SpectrumTypes.PERSNICKETY
  }, {
    text: 'The overwhelming volume of attractive, charming suitors reaching out to you makes online dating unappealing.',
    type: SpectrumTypes.LOVABLE
  }, {
    text: 'You have, on at least one occasion, actively considered applying for asylum in Canada after receiving an invitation to a house party.',
    type: SpectrumTypes.ANTICHRIST
  }, {
    text: 'People will attempt to seat themselves several times in a chair you’re occupying before realizing you’re there.',
    type: SpectrumTypes.BARNACLE
  }, {
    text: 'You never shut the fuck up.',
    type: SpectrumTypes.CHRIST
  }, {
    text: 'When people are nearing their breaking point, you’re always there to finish them off.',
    type: SpectrumTypes.PARASITE
  }, {
    text: 'Your hair is worth celebrating.',
    type: SpectrumTypes.OTHER
  }, {
    text: 'You have had a pie-eating contest, sexual position, or stomach-pumping technique named after you.',
    type: SpectrumTypes.ORGIASTIC
  }, {
    text: 'Priests have put a time limit on your confessional visits.',
    type: SpectrumTypes.PARASITE
  }, {
    text: 'Your pet goldfish is the alpha of your household.',
    type: SpectrumTypes.BARNACLE
  }, {
    text: 'This is the year the Rockets win it all.',
    type: SpectrumTypes.OTHER
  }, {
    text: 'You are currently taking this assessment with an entire foot-long sub in your mouth and another inserted into your rectum.',
    type: SpectrumTypes.ORGIASTIC
  }, {
    text: 'You have declined to attend the birth of one or more of your children for fear of meeting new people.',
    type: SpectrumTypes.ANTICHRIST
  }, {
    text: 'You thrive when working on a deadline, your whole body throbbing in ecstasy at the sound of a ticking clock.',
    type: SpectrumTypes.PERSNICKETY
  }, {
    text: 'Animals would rather stick it out at the shelter than be adopted by you.',
    type: SpectrumTypes.UNLOVABLE
  }, {
    text: 'Friends would describe you as “spontaneous” or “the nude one covered in sprinkles currently being led away by the authorities.”',
    type: SpectrumTypes.ORGIASTIC
  }
]
