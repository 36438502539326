import React, { Component } from 'react'

import * as S from './styles'

export default class Disclaimer extends Component {
  render () {
    return <S.Disclaimer {...this.props}>
      <S.DisclaimerText>
        <S.StyledClose onClick={this.props.close}>&times;</S.StyledClose>
        <strong>IMPORTANT:</strong> As indicated by the name, this test has been calibrated to take users exactly 6 minutes to complete, not a second more or less. Should you be unable to complete the test in precisely 6 minutes, you are advised to discard your results and start again. The 6 Minute Test is not responsible for any test results acquired within a different time frame. Please contact us if you are consistently unable to take the test in 6 minutes, as there might be an issue with your personality.
      </S.DisclaimerText>
    </S.Disclaimer>
  }
}
