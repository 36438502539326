import React, { Component } from 'react'
import { renderRoutes } from 'react-router-config'
import Helmet from 'react-helmet'

import * as S from './styles'
import { Global } from 'app/styles'
import ModalController from '../modals/ModalController'
import ogImage from '../../assets/images/ogImage.png'
import favicon from '../../assets/images/favicon.png'

export default class Root extends Component {
  componentDidMount () {
    let isIE = /* @cc_on!@ */false || !!document.documentMode

    if (isIE) this.props.history.push(`/ie`)
  }
  render () {
    const description = 'With 16 different personality types, our completely free, scientifically influenced personality assessment is as unique as you are. So what are you waiting for? Take a shortcut to self-knowledge now!'
    const title = '6 Minute Personality Test'
    return <S.Root>
      <Helmet
        title={title}
        meta={[
          { name: 'description', content: description },
          { property: 'og:description', content: description },
          { property: 'og:title', content: title },
          { property: 'og:image', content: ogImage },
          { property: 'og:image:secure_url', content: ogImage },
          { property: 'og:type', content: 'website' },
          { property: 'twitter:card', content: 'summary_large_image' },
          { property: 'twitter:title', content: title },
          { property: 'twitter:description', content: description },
          { property: 'twitter:image', content: ogImage }
        ]}
        link={[
          { 'rel': 'icon',
            'type': 'image/png',
            'href': favicon
          }
        ]}
      />
      <Global.GlobalStyle />
      <ModalController />
      { renderRoutes(this.props.route.routes) }
    </S.Root>
  }
}
