import Famous1Img from '../../../assets/images/pngs/famous/11-Comcast/01-AttilaTheHun-11.png'
import Famous2Img from '../../../assets/images/pngs/famous/11-Comcast/02-Bowser-11.png'
import Famous3Img from '../../../assets/images/pngs/famous/11-Comcast/03-TimeWarnerCable-11.png'
import Famous4Img from '../../../assets/images/pngs/famous/11-Comcast/04-LeonaHelmsley-11.png'
import Famous5Img from '../../../assets/images/pngs/famous/11-Comcast/05-God-11.png'
import Famous6Img from '../../../assets/images/pngs/famous/11-Comcast/06-Bernie-Madoff-11.png'
import Famous7Img from '../../../assets/images/pngs/famous/11-Comcast/07-Shub-Niggurath-11.png'
import Famous8Img from '../../../assets/images/pngs/famous/11-Comcast/08-Congress-11.png'
import Famous9Img from '../../../assets/images/pngs/famous/11-Comcast/09-HAL-9000-11.png'
import Famous10Img from '../../../assets/images/pngs/famous/11-Comcast/10-UConn-11.png'

export default {
  Famous2: { img: Famous2Img, name: 'Bowser' },
  Famous3: { img: Famous3Img, name: 'Time Warner Cable' },
  Famous4: { img: Famous4Img, name: 'Leona Helmsley' },
  Famous5: { img: Famous5Img, name: 'God' },
  Famous6: { img: Famous6Img, name: 'Bernie Madoff' },
  Famous7: { img: Famous7Img, name: 'Shub-Niggurath' },
  Famous8: { img: Famous8Img, name: 'Congress' },
  Famous9: { img: Famous9Img, name: 'HAL 9000' },
  Famous10: { img: Famous10Img, name: 'UConn Women’s Basketball Team' },
  Famous1: { img: Famous1Img, name: 'Attila The Hun' }
}
