import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import * as S from './styles'
import { PersonalityTypes } from '../../data/personalityTypes'

const ShareTypes = {
  linkedIn: {
    url: 'https://linkedin.com/',
    slug: 'lnkd',
    icon: <S.StyledLinkedin />,
    shareUrl: 'https://www.linkedin.com/shareArticle?mini=true&url=',
    shareExtra: '&summary='
  },
  twitter: {
    url: 'https://twitter.com/',
    slug: 'twtr',
    icon: <S.StyledTwitter />,
    shareUrl: 'http://www.twitter.com/intent/tweet?url=',
    shareExtra: '&text='
  },
  facebook: {
    url: 'https://facebook.com/',
    slug: 'fb',
    icon: <S.StyledFacebook />,
    shareUrl: 'https://www.facebook.com/sharer/sharer.php?u='
  }
}

@withRouter
export default class SocialShare extends Component {
  renderShareType = (type) => {
    const { match } = this.props
    if (typeof window === 'undefined') return
    let url = `${type.shareUrl}${window.location.href}`
    const personalityType = match.params.type
    const personality = PersonalityTypes[personalityType]

    const description = `I just found out that I'm ${personality.shareIdent || 'a'} ${personality.label.replace('The ', '')}! Which of the 16 personality types are you?`
    if (type.shareExtra) {
      url = url + type.shareExtra + description
      if (type.slug === 'twtr') {
        url = url + '&hashtags=sixminutetest'
      }
      url = encodeURI(url)
    }

    return <a target='_blank' href={url} key={type.slug}>
      {type.icon}
    </a>
  }
  render () {
    return <S.SocialShare>
      { Object.values(ShareTypes).map(type => {
        return this.renderShareType(type)
      }) }
    </S.SocialShare>
  }
}
