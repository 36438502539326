import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import * as S from './styles'
import Footer from '../../components/Footer/Footer'
import Nav from '../../components/Nav/Nav'
import { PersonalityTypes } from '../../data/personalityTypes'
import PersonalityInfo from '../../components/PersonalityInfo/PersonalityInfo'

const mapStateToProps = (state) => {
  const { answers } = state.app
  return { answers }
}

@withRouter
@connect(mapStateToProps, null)
export default class Personality extends Component {
  renderHelmet = () => {
    const { match } = this.props
    const personalityType = match.params.type
    const personality = PersonalityTypes[personalityType]
    const description = `Are you ${personality.shareIdent || 'a'} ${personality.label.replace('The ', '')}? Find out at sixminutetest.com`

    return <Helmet
      meta={[
        { name: 'description', content: description },
        { property: 'og:description', content: description },
        { property: 'og:image', content: personality.heroImg },
        { property: 'twitter:description', content: description },
        { property: 'twitter:image', content: personality.heroImg }
      ]}
    />
  }
  render () {
    return <S.Personality>
      { this.renderHelmet() }
      <Nav />
      <PersonalityInfo />
      <Footer />
    </S.Personality>
  }
}
