import React, { Component } from 'react'

import * as S from './styles'
import NavLogo from '../../components/NavLogo/NavLogo'

export default class InternetExplorer extends Component {
  render () {
    return <S.InternetExplorer>
      <S.LogoWrap href='/'>
        <NavLogo />
        <S.LogoText>6 Minute Test</S.LogoText>
      </S.LogoWrap>
      <span>Given that you're using Internet Explorer, we have determined that you have no personality.</span>
    </S.InternetExplorer>
  }
}
