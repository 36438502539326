import React, { Component } from 'react'

import * as S from './styles'
import Hero from '../../components/Hero/Hero'
import Footer from '../../components/Footer/Footer'
import Nav from '../../components/Nav/Nav'

export default class NotFound extends Component {
  render () {
    return <S.NotFound>
      <Nav />
      <Hero type='404' />
      <Footer />
    </S.NotFound>
  }
}
