import styled, { css } from 'styled-components'

import { Animations, Type, Breakpoints, Colors } from 'app/styles'

export const PersonalitiesWrap = styled.div`
  animation: ${Animations.FadeIn} 1250ms forwards;
  animation-delay: 250ms;
  opacity: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
`

export const Title = styled.h1`
  margin: 0;
  padding: 0;
`

export const Personalities = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 10px 80px 40px;
  box-sizing: border-box;
`

export const Avatar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  z-index: 50;
  overflow: hidden;
  border: 10px solid ${props => props.color};
  box-sizing: border-box;
  transition: all 150ms ease-out;

  img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 15px) scale(1);
    height: 100%;
    transform-origin: bottom center;
    transition: all 450ms ease-out;
    z-index: 50;
  }
`

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 250ms ease-out;

  ${props => props.bgImg && css`
    background: url(${props.bgImg}) no-repeat center center;
    background-size: 350%;
    opacity: .4;
  `}
`

export const Name = styled.p`
  display: flex;
  flex-direction: column;
  width: 90%;
  ${Type.Body};
  box-sizing: border-box;
  border-radius: 3px;
  text-align: center;
  margin: 0 auto;
  margin-top: 5px;
  color: ${Colors.navy};

  strong {
    ${Type.SmallSecondary};
  }
`

export const Personality = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 80%;
  padding-top: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  box-sizing: border-box;
  transition: all 250ms ease-out;
  background: ${props => props.color};
  border-radius: 100%;
  transform: scale(.85);
`

export const PersonalityWrap = styled.a`
  width: 25%;
  margin-bottom: 25px;
  cursor: pointer;

  &:hover {
    ${Personality} {
      transform: scale(.95);
      border-width: 0px;
    }

    ${Name} {
      strong {
        color: ${props => props.color};
      }
    }

    ${Avatar} {
      border-width: 0px;

      :before {
        height: 110%;
        width: 110%;
      }

      img {
        transform: translate(-50%, 5px) scale(1.05);
      }
    }

    ${Background} {
      background-size: 250%;
      opacity: 1;
    }
  }

  @media (max-width: ${Breakpoints.large}) {
    width: 50%;
  }

  @media (max-width: ${Breakpoints.small}) {
    width: 100%;
  }
`
