import Famous1Img from '../../../assets/images/pngs/famous/14-Dramaclub/01-LorneMichaels-14.png'
import Famous2Img from '../../../assets/images/pngs/famous/14-Dramaclub/02-AnnCurry-14.png'
import Famous3Img from '../../../assets/images/pngs/famous/14-Dramaclub/03-TheHolySpirit-14.png'
import Famous4Img from '../../../assets/images/pngs/famous/14-Dramaclub/04-OptimusPrime-14.png'
import Famous5Img from '../../../assets/images/pngs/famous/14-Dramaclub/05-Clippy-14.png'
import Famous6Img from '../../../assets/images/pngs/famous/14-Dramaclub/06-CondoleezzaRice-14.png'
import Famous7Img from '../../../assets/images/pngs/famous/14-Dramaclub/07-LandOLakesMaiden-14.png'
import Famous8Img from '../../../assets/images/pngs/famous/14-Dramaclub/08-KenBurns-14.png'
import Famous9Img from '../../../assets/images/pngs/famous/14-Dramaclub/09-Confucius-14.png'
import Famous10Img from '../../../assets/images/pngs/famous/14-Dramaclub/10-DaveMatthewsBand-14.png'

export default {
  Famous2: { img: Famous2Img, name: 'Ann Curry' },
  Famous3: { img: Famous3Img, name: 'The Holy Spirit' },
  Famous4: { img: Famous4Img, name: 'Optimus Prime' },
  Famous5: { img: Famous5Img, name: 'Clippy' },
  Famous6: { img: Famous6Img, name: 'Condoleezza Rice' },
  Famous7: { img: Famous7Img, name: 'Land O’ Lakes Maiden' },
  Famous8: { img: Famous8Img, name: 'Ken Burns' },
  Famous9: { img: Famous9Img, name: 'Confucius' },
  Famous10: { img: Famous10Img, name: 'Dave Matthews Band' },
  Famous1: { img: Famous1Img, name: 'Lorne Michaels' }
}
