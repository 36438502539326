import { PersonalityTypes } from './personalityTypes'

export const testimonials = [
  {
    name: 'Kimberly',
    text: 'The 6 Minute Test was so accurate. It perfectly described my unsettling mannerisms and the way that other people tremble in terror at the very sight of me!',
    type: PersonalityTypes.UBOC
  }, {
    name: 'Desiree',
    text: 'To learn that you’re not a complete outsider, but rather a member of one of a finite number of possible categories, each containing hundreds of millions of individuals, is incredibly empowering.',
    type: PersonalityTypes.LBYA
  }, {
    name: 'Avery',
    text: 'This test totally changed the way I see my flaws. Now that I know they’re part of my personality, I never have to change!',
    type: PersonalityTypes.LPYA
  }, {
    name: 'Jennifer',
    text: 'In a sea of online personality tests that promise to reveal your true character and use it to chart a path toward personal and professional success, this test is one of them.',
    type: PersonalityTypes.LPOC
  }, {
    name: 'Lupita',
    text: 'The best part is that the website assured me science was somehow involved, so I know the results are accurate.',
    type: PersonalityTypes.LBYC
  }, {
    name: 'Deepa',
    text: 'My results finally persuaded me to stop listening to my conscience and just embrace the engine of pure destruction I was meant to be!',
    type: PersonalityTypes.UPOC
  }, {
    name: 'Carol',
    text: 'I always knew I was four letters, but until I took this test, I had no idea which four.',
    type: PersonalityTypes.UPYA
  }, {
    name: 'Ali',
    text: 'I used to think I was the only one who feels the way I do. Now I know that there are plenty of others like me in the world, all of us united in a great brotherhood of suffering!',
    type: PersonalityTypes.UBOA
  }, {
    name: 'Zach',
    text: 'This test gave me the courage to completely and irreversibly change the course of my life. Thanks, whoever wrote it!',
    type: PersonalityTypes.UBYA
  }, {
    name: 'Marv',
    text: 'My common-law wife and I both took the test, and the experience of reading each other’s profiles was so intimate that it’s become a regular part of our BDSM foreplay rituals.',
    type: PersonalityTypes.LBOA
  }, {
    name: 'Elise',
    text: 'Sure, this might all seem like trivial nonsense when I lose interest and move on to the next shiny object on the internet that captures my constantly wandering attention, but for at least the next several moments I can’t help but feel empowered!',
    type: PersonalityTypes.LBOC
  }
]
