import React from 'react'

export default (props) =>
  <svg {...props} width='21px' height='19px' viewBox='0 0 21 19' preserveAspectRatio='xMidYMid'>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Group-2' transform='translate(1.000000, 1.000000)'>
        <g id='Group-4' transform='translate(4.000000, 7.081347)'>
          <g id='Group' transform='translate(0.433333, 0.409183)' />
        </g>
        <path d='M0.088252587,8.91828929 L4.50877626,16.7444039 C4.62644638,16.9528425 4.84421057,17.0813466 5.07969975,17.0813466 L13.9204492,17.0813466 C14.1559384,17.0813466 14.3735536,16.9528425 14.4912237,16.7444039 L18.9117474,8.91828929 C19.0294175,8.70969846 19.0294175,8.45284246 18.9117474,8.24440389 L14.4912237,0.418289289 C14.3735536,0.20985072 14.1559384,0.0813465909 13.9204492,0.0813465909 L5.07969975,0.0813465909 C4.84421057,0.0813465909 4.62644638,0.20985072 4.50877626,0.418289289 L0.088252587,8.24440389 C-0.029417529,8.45284246 -0.029417529,8.70969846 0.088252587,8.91828929' id='Fill-1' stroke='#FFF7EF' />
        <polyline id='Path-3-Copy' stroke='#FFF7EF' transform='translate(12.559668, 8.413606) scale(1, -1) translate(-12.559668, -8.413606) ' points='10.659668 4.49052934 14.459668 8.41360626 10.659668 12.3366832' />
        <path d='M13.8263346,8.41360626 L4.69072546,8.41360626' id='Line' stroke='#FFF7EF' strokeLinecap='square' />
      </g>
    </g>
  </svg>
