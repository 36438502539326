import Famous1Img from '../../../assets/images/pngs/famous/16-9HeadedBeast/01-Tyra-Banks-16.png'
import Famous2Img from '../../../assets/images/pngs/famous/16-9HeadedBeast/02-MehmetOz.png'
import Famous3Img from '../../../assets/images/pngs/famous/16-9HeadedBeast/03-MikeKrzyzewski-16.png'
import Famous4Img from '../../../assets/images/pngs/famous/16-9HeadedBeast/04-Grimace-16.png'
import Famous5Img from '../../../assets/images/pngs/famous/16-9HeadedBeast/05-Investment-Banking-16.png'
import Famous6Img from '../../../assets/images/pngs/famous/16-9HeadedBeast/06-KevinFederline-16.png'
import Famous7Img from '../../../assets/images/pngs/famous/16-9HeadedBeast/07-AnnCoulter-16.png'
import Famous8Img from '../../../assets/images/pngs/famous/16-9HeadedBeast/08-Monsanto-16.png'
import Famous9Img from '../../../assets/images/pngs/famous/16-9HeadedBeast/09-Macklemore.png'
import Famous10Img from '../../../assets/images/pngs/famous/16-9HeadedBeast/10-CaptainKangaroo-16.png'

export default {
  Famous2: { img: Famous2Img, name: 'Mehmet Oz' },
  Famous3: { img: Famous3Img, name: 'Mike Krzyzewski' },
  Famous4: { img: Famous4Img, name: 'Grimace' },
  Famous5: { img: Famous5Img, name: 'Investment Banking' },
  Famous6: { img: Famous6Img, name: 'Kevin Federline' },
  Famous7: { img: Famous7Img, name: 'Ann Coulter' },
  Famous8: { img: Famous8Img, name: 'Monsanto' },
  Famous9: { img: Famous9Img, name: 'Macklemore' },
  Famous10: { img: Famous10Img, name: 'Captain Kangaroo' },
  Famous1: { img: Famous1Img, name: 'Tyra Banks' }
}
