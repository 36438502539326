export const ActionTypes = {
  SET_ACTIVE_MODAL: 'SET_ACTIVE_MODAL',
  UPDATE_TEST: 'UPDATE_TEST'
}

export const closeAllModals = () => ({ type: ActionTypes.SET_ACTIVE_MODAL, modal: null })
export const openModal = ({ modalName, modalState }) => ({
  type: ActionTypes.SET_ACTIVE_MODAL,
  modal: { name: modalName, modalState }
})

export const updateTest = ({ answers }) => ({
  type: ActionTypes.UPDATE_TEST,
  answers: answers,
  updated: new Date().getTime()
})
