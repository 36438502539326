import React, { Component } from 'react'

import * as S from './styles'

export default class ProgressBar extends Component {
  render () {
    const progress = (this.props.complete / this.props.total) * 100
    return <S.ProgressBar
      {...this.props}
      progress={`${progress.toFixed(2)}%`}
    />
  }
}
