import styled from 'styled-components'

import { Colors, Breakpoints } from 'app/styles'

export const Snackbar = styled.div`
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translate(-50%, -0);
  width: calc(100% - 20px);
  max-width: 960px;
  background: ${Colors.yellowLight};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;

  @media (max-width: ${Breakpoints.main}) {
    flex-direction: column;
  }
`

export const Message = styled.strong`
  margin: 0;
  margin-left: 5px;
`

export const Actions = styled.div`

`
