import styled, { css } from 'styled-components'

import { Colors, Breakpoints } from 'app/styles'

export const QuestionInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;

  small {
    font-size: 16px;
    opacity: .5;
  }

  @media (max-width: ${Breakpoints.main}) {
    flex-flow: row wrap;
  }
`

export const Selections = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  @media (max-width: ${Breakpoints.main}) {
    width: 100%;
    order: 3;
    margin-top: 10px;
    justify-content: space-between;
  }
`

export const StyledSelection = styled.div`
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  opacity: .4;
  border: 2px solid ${Colors.orange};

  &:nth-of-type(1) {
    border-color: ${Colors.orange};
  }

  &:nth-of-type(2) {
    border-color: ${Colors.orange};
  }

  &:nth-of-type(3) {
    border-color: ${Colors.gray};
  }

  &:nth-of-type(4) {
    border-color: ${Colors.gray};
  }

  &:nth-of-type(5) {
    border-color: ${Colors.green};
  }

  &:nth-of-type(6) {
    border-color: ${Colors.green};
  }

  &:hover {
    opacity: .5;

    &:nth-of-type(1) {
      background-color: ${Colors.orange};
    }

    &:nth-of-type(2) {
      background-color: ${Colors.orange};
    }

    &:nth-of-type(3) {
      background-color: ${Colors.gray};
    }

    &:nth-of-type(4) {
      background-color: ${Colors.gray};
    }

    &:nth-of-type(5) {
      background-color: ${Colors.green};
    }

    &:nth-of-type(6) {
      background-color: ${Colors.green};
    }
  }

  ${props => props.selected && css`
    opacity: 1;

    &:nth-of-type(1) {
      background-color: ${Colors.orange};
    }

    &:nth-of-type(2) {
      background-color: ${Colors.orange};
    }

    &:nth-of-type(3) {
      background-color: ${Colors.gray};
    }

    &:nth-of-type(4) {
      background-color: ${Colors.gray};
    }

    &:nth-of-type(5) {
      background-color: ${Colors.green};
    }

    &:nth-of-type(6) {
      background-color: ${Colors.green};
    }
  `}
`
