import styled, { css } from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'
import 'animate.css/animate.min.css'

import { Animations, Type, Colors, Breakpoints } from 'app/styles'
import { BackToTop } from 'app/ui-kit/Icons'

export const StyledScrollAnimation = styled(ScrollAnimation)`
  z-index: 50;
`

export const StyledImageScroll = styled(ScrollAnimation)`
  z-index: 50;
`

export const SpectrumsWrap = styled.div`
  animation: ${Animations.FadeIn} 1250ms forwards;
  animation-delay: 250ms;
  opacity: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SpectrumImageMobile = styled.div`
  position: relative;
  @media (max-width: ${Breakpoints.large}) {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto;
    background: ${props => props.color};
  }
`

export const SpectrumImage = styled.img`
  height: 500px;
  flex-shrink: 0;
  z-index: 50;
  margin-top: -55px;

  @media (max-width: ${Breakpoints.large}) {
    height: auto;
    width: 150px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0, ${props => props.offset});
  }
`

export const Title = styled.div`
  ${Type.Large};
  max-width: 900px;
  margin: 0;
  padding: 0 80px;
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
  text-align: center;
  margin-bottom: 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${Breakpoints.large}) {
    padding: 25px 25px 35px;
    margin: 0;
    ${Type.LargeInput};
    height: 200px;
  }
`

export const Spectrums = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledBackToTop = styled(BackToTop)`
  position: fixed;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  height: 25px;
  width: 25px;
  padding: 10px;
  border: 2px solid ${Colors.background};
  fill: ${Colors.background};
  border-radius: 3px;
  margin-left: 15px;
  transform: scale(.95);
  transition: transform 150ms ease-out, bottom 75ms ease-out;
  animation: ${Animations.FadeIn} 450ms forwards;

  &:hover {
    transform: scale(1);
  }

  ${props => props.bottom && css`
    bottom: 90px;

    @media (max-width: ${Breakpoints.main}) {
      bottom: 150px;
    }
  `}
`

export const SpectrumGroup = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: 35px;
  width: 100%;
  max-width: 500px;

  ul {
    list-style: none;
    margin: 0;
    margin-top: 10px;
    padding: 0;
  }

  li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${Breakpoints.large}) {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }
`

export const Border = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: 500px;
  width: 100%;
  padding-left: 35px;

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    height: 10px;
    background: ${props => props.color};
    border-radius: 8px;

    @media (max-width: ${Breakpoints.large}) {
      display: none;
    }
  }

  @media (max-width: ${Breakpoints.large}) {
    padding-left: 0;
    flex-direction: column;
  }
`

export const Name = styled(ScrollAnimation)`
  ${Type.MediumSecondary};
  white-space: nowrap;
  position: relative;
  margin-top: 0;
  padding-bottom: 5px;
  margin-bottom: 20px;

  @media (max-width: ${Breakpoints.large}) {
    margin-top: 25px;

    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 10px;
      background: ${props => props.color};
      border-radius: 5px;
    }
  }
`

export const Spectrum = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;
  background: ${props => props.color};
  padding: 350px 25px 100px 50px;
  box-sizing: border-box;

  @media (max-width: ${Breakpoints.large}) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 150px 25px;

    ${StyledImageScroll} {
      order: -1;
    }
    ${props => !props.leftAlign && css`
      padding-top: 200px;
    `}
  }

  ${props => props.leftAlign && css`
    justify-content: flex-start;
    padding: 350px 50px 100px 25px;

    ${StyledImageScroll} {
      order: -1;
    }
    ${Content} {
      margin-right: 0;
      margin-left: 25px;
      @media (max-width: ${Breakpoints.large}) {
        margin-left: 0;
        margin-right: 0;
        text-align: center;
      }
    }
    ${Border} {
      padding-left: 0;
      padding-right: 25px;
      @media (max-width: ${Breakpoints.large}) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  `}
`
