import Famous1Img from '../../../assets/images/pngs/famous/02-46_DJ/01-Chuck-Schumer-02.png'
import Famous2Img from '../../../assets/images/pngs/famous/02-46_DJ/02-The-Spice-Girls-02.png'
import Famous3Img from '../../../assets/images/pngs/famous/02-46_DJ/03-Yoshi-02.png'
import Famous4Img from '../../../assets/images/pngs/famous/02-46_DJ/04-AnnieLeibovitz-02.png'
import Famous5Img from '../../../assets/images/pngs/famous/02-46_DJ/05-YellowMM-02.png'
import Famous6Img from '../../../assets/images/pngs/famous/02-46_DJ/06-Koko-02.png'
import Famous7Img from '../../../assets/images/pngs/famous/02-46_DJ/07-Snap-02.png'
import Famous8Img from '../../../assets/images/pngs/famous/02-46_DJ/08-Crackle-02.png'
import Famous9Img from '../../../assets/images/pngs/famous/02-46_DJ/09-Hirohito-02.png'
import Famous10Img from '../../../assets/images/pngs/famous/02-46_DJ/10-Rockafeller-tree-02.png'

export default {
  Famous2: { img: Famous2Img, name: 'The Spice Girls' },
  Famous3: { img: Famous3Img, name: 'Yoshi' },
  Famous4: { img: Famous4Img, name: 'Annie Leibovitz' },
  Famous5: { img: Famous5Img, name: 'The Yellow M&M' },
  Famous6: { img: Famous6Img, name: 'Koko' },
  Famous7: { img: Famous7Img, name: 'Snap' },
  Famous8: { img: Famous8Img, name: 'Crackle' },
  Famous9: { img: Famous9Img, name: 'Hirohito' },
  Famous10: { img: Famous10Img, name: 'Rockefeller Center Christmas Tree' },
  Famous1: { img: Famous1Img, name: 'Chuck Schumer' }
}
