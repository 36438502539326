import React from 'react'

import Famous1Img from '../../../assets/images/pngs/famous/09-Murderous/01-NewtGingrich-09.png'
import Famous2Img from '../../../assets/images/pngs/famous/09-Murderous/02-LanceArmstrong-08.png'
import Famous3Img from '../../../assets/images/pngs/famous/09-Murderous/03-USA-09.png'
import Famous4Img from '../../../assets/images/pngs/famous/09-Murderous/04-RedMM-09.png'
import Famous5Img from '../../../assets/images/pngs/famous/09-Murderous/05-BlacChyna-09.png'
import Famous6Img from '../../../assets/images/pngs/famous/09-Murderous/06-Yahoo-09.png'
import Famous7Img from '../../../assets/images/pngs/famous/09-Murderous/07-TammyFayeBakker-09.png'
import Famous8Img from '../../../assets/images/pngs/famous/09-Murderous/08-TheDuggars-09.png'
import Famous9Img from '../../../assets/images/pngs/famous/09-Murderous/09-Up-09.png'
import Famous10Img from '../../../assets/images/pngs/famous/09-Murderous/10-Batboy-09.png'

export default {
  Famous2: { img: Famous2Img, name: 'Lance Armstrong' },
  Famous3: { img: Famous3Img, name: 'The United States Of America' },
  Famous4: { img: Famous4Img, name: 'The Red M&M' },
  Famous5: { img: Famous5Img, name: 'Blac Chyna' },
  Famous6: { img: Famous6Img, name: 'Yahoo!' },
  Famous7: { img: Famous7Img, name: 'Tammy Faye Bakker' },
  Famous8: { img: Famous8Img, name: 'The Duggars' },
  Famous9: { img: Famous9Img, name: <p>The Old Man From <span>Up</span></p> },
  Famous10: { img: Famous10Img, name: 'Bat Boy' },
  Famous1: { img: Famous1Img, name: 'Newt Gingrich' }
}
