import styled, { css } from 'styled-components'

import { Colors, Breakpoints } from 'app/styles'

export const Button = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  transition: all 275ms ease;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.white};
  background: ${Colors.teal};
  border-radius: 36px;
  padding: .5em;

  &:hover {
    background: ${Colors.tealDark};
    color: ${Colors.white};
  }
  @media (max-width: ${Breakpoints.main}) {
    height: 35px;
  }

  ${props => props.disabled && css`
    opacity: .3;
    pointer-events: none;
    transform: scale(.95);
  `}

  ${props => props.gray && css`
    background: ${Colors.black012};

    &:hover {
      background: ${Colors.black};
      color: ${Colors.white};
    }
  `}

  ${props => props.secondary && css`
    background: ${Colors.orange};

    &:hover {
      background: ${Colors.orangeDark};
      color: ${Colors.white};
    }
  `}

  ${props => props.skinny && css`
    height: 35px;
    padding: 0px;
  `}
`

export const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  width: 100%;
  text-transform: uppercase;

  @media (max-width: ${Breakpoints.main}) {
    padding: 0 8px;
  }
`
