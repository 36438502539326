import styled from 'styled-components'

import { Breakpoints } from 'app/styles'

export const Testimonials = styled.div`
  padding: 50px;
  box-sizing: border-box;
  width: 100%;
`

export const Testimonial = styled.div`
  box-sizing: border-box;
  text-align: center;
  width: calc((100% / 3) - 20px);
  margin: 10px;
  padding: 15px 0;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    border: 2px solid ${props => props.color};
    pointer-events: none;
  }

  &:focus {
    outline: 0;
  }

  strong {
    display: block;
    color: ${props => props.color};
    margin-bottom: auto;
  }

  p {
    max-width: 350px;
    margin: auto;
    padding: 0 25px;
  }

  @media (max-width: ${Breakpoints.main}) {
    width: calc(100% - 20px);
  }
`
