import React, { Component } from 'react'

import * as S from './styles'
import Hero from '../../components/Hero/Hero'
import Footer from '../../components/Footer/Footer'
import Nav from '../../components/Nav/Nav'
import Testimonials from '../../components/Testimonials/Testimonials'

export default class Home extends Component {
  render () {
    return <S.Home>
      <Nav />
      <Hero flushBottom type='home' />
      <Testimonials />
      <Footer />
    </S.Home>
  }
}
