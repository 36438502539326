import { SpectrumGroups, SpectrumTypes } from '../data/spectrums'
import { questions } from '../data/questions'

export const calculateTieBreaker = (typeA, typeB, answers) => {
  let personalityType = 'X'
  questions.map((question, index) => {
    if (question.tiebreaker) {
      const questionAcronym = question.type.acronym
      if (questionAcronym === typeA.typeChar) {
        const answerValue = answers[index]
        if (answerValue > 0) personalityType = typeA.typeChar
        else if (answerValue < 0) personalityType = typeB.typeChar
      } else if (questionAcronym === typeB.typeChar) {
        const answerValue = answers[index]
        if (answerValue > 0) personalityType = typeB.typeChar
        else if (answerValue < 0) personalityType = typeA.typeChar
      }
    }
  })

  return personalityType
}

export const calculateTypes = (answers) => {
  let types = []
  SpectrumTypes.enumValues.map(spectrumType => {
    if (spectrumType.acronym) {
      types[spectrumType.acronym] = {
        value: 0,
        typeChar: spectrumType.acronym
      }
    }
  })
  Object.keys(answers).map((key) => {
    const answer = answers[key]
    const question = questions[key]
    const typeChar = question.type.acronym
    if (!typeChar) return
    let oldValue = 0
    if (types[typeChar]) oldValue = types[typeChar].value
    types[typeChar] = { value: oldValue + parseInt(answer), typeChar: typeChar }
  })
  return types
}

export const getPersonalityType = (types, answers) => {
  let personalityType = ''
  let ties = 0

  SpectrumGroups.enumValues.map(group => {
    let charA = group.types[0].acronym
    let charB = group.types[1].acronym
    if (types[charA].value + types[charB].value === 0) {
      personalityType = personalityType + calculateTieBreaker(types[charA], types[charB], answers)
      ties++
    } else if (types[charA].value > types[charB].value) {
      personalityType = personalityType + charA
    } else {
      personalityType = personalityType + charB
    }
  })

  const completed = getCompleted(answers)
  if (ties === 4 && completed.length === questions.length) return '17th'

  return personalityType
}

export const getCompleted = (answers) => {
  if (!answers) return { answers: null, length: 0 }
  let completed = []
  Object.values(answers).map((answer, index) => {
    if (answer !== 0) completed[index] = answer
  })
  return { answers: completed, length: Object.keys(completed).length }
}

export const testMath = (answers) => {
  if (!answers) return
  const types = calculateTypes(answers)
  const personalityType = getPersonalityType(types, answers)
  return { types, personalityType }
}

export const sortTypes = (types) => {
  return Object.values(types).sort((a, b) => {
    return b.value - a.value
  })
}
