import Famous1Img from '../../../assets/images/pngs/famous/15-BirthdayRuiner/01-Pandora-15.png'
import Famous2Img from '../../../assets/images/pngs/famous/15-BirthdayRuiner/02-KimJongil-15.png'
import Famous3Img from '../../../assets/images/pngs/famous/15-BirthdayRuiner/03-TaraReid-15.png'
import Famous4Img from '../../../assets/images/pngs/famous/15-BirthdayRuiner/04-TheCatInTheHat-15.png'
import Famous5Img from '../../../assets/images/pngs/famous/15-BirthdayRuiner/05-Lady-Macbeth-15.png'
import Famous6Img from '../../../assets/images/pngs/famous/15-BirthdayRuiner/06-JimJones-15.png'
import Famous7Img from '../../../assets/images/pngs/famous/15-BirthdayRuiner/07-MtVesuvius-15.png'
import Famous8Img from '../../../assets/images/pngs/famous/15-BirthdayRuiner/08-JosefMengele-15.png'
import Famous9Img from '../../../assets/images/pngs/famous/15-BirthdayRuiner/09-Medusa-15.png'
import Famous10Img from '../../../assets/images/pngs/famous/15-BirthdayRuiner/10-Ayn-Rand-15.png'

export default {
  Famous2: { img: Famous2Img, name: 'Kim Jong-il' },
  Famous3: { img: Famous3Img, name: 'Tara Reid' },
  Famous4: { img: Famous4Img, name: 'The Cat In The Hat' },
  Famous5: { img: Famous5Img, name: 'Lady Macbeth' },
  Famous6: { img: Famous6Img, name: 'Jim Jones' },
  Famous7: { img: Famous7Img, name: 'Mt. Vesuvius' },
  Famous8: { img: Famous8Img, name: 'Josef Mengele' },
  Famous9: { img: Famous9Img, name: 'Medusa' },
  Famous10: { img: Famous10Img, name: 'Ayn Rand' },
  Famous1: { img: Famous1Img, name: 'Pandora' }
}
