import styled from 'styled-components'

import { Animations, Colors, Breakpoints } from 'app/styles'

export const PrivacyPolicy = styled.div`
  animation: ${Animations.FadeIn} 1250ms forwards;
  animation-delay: 250ms;
  opacity: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`

export const PolicyWrap = styled.div`
  margin: 75px 80px;
  margin-top: 100px;
  padding: 25px;
  border-radius: 3px;
  background: ${Colors.background};
  max-width: ${Breakpoints.large};

  p {
    margin-top: 0;
  }

  strong {
    display: block;
    margin-bottom: 3px;
    margin-top: 35px;
  }

  a {
    color: ${Colors.navy};
  }

  a:hover {
    opacity: .7;
  }

  @media {
    margin: 75px 20px;
  }
`
