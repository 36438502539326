import styled, { css } from 'styled-components'

import { Colors, Breakpoints } from 'app/styles'

export const Carousel = styled.div`
  cursor: pointer;
  width: 100%;
  position: relative;
  opacity: 0;
  transition: opacity 150ms ease-out;

  &:focus {
    outline: none;
  }

  .flickity-viewport {
    overflow: hidden;
  }

  .flickity-prev-next-button {
    cursor: pointer;
    background: none;
    outline: none;
    border: none;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .flickity-prev-next-button .arrow {
    fill: ${Colors.orange};
  }
  .flickity-prev-next-button.no-svg {
    fill: ${Colors.orange};
  }
  
  .flickity-button-icon {
    margin-top: 2px;
  }

  .previous,
  .next {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  .flickity-prev-next-button.previous {
    position: absolute;
    left: -30px;
  }
  .flickity-prev-next-button.next {
    position: absolute;
    right: -30px;
  }

  ${props => props.visible && css`
    opacity: 1;
  `}

  @media (min-width: ${Breakpoints.main}) {
    ${props => props.disabled && css`
      pointer-events: none;

      .previous,
      .next {
        display: none;
      }
    `}
  }
`

export const StyledArrow = styled.div`
  fill: transparent;
  transform: translate(20px, 0);
  cursor: pointer;

  ${props => props.left && css`
    transform: rotate(180deg) translate(20px, 0);
  `}
`
