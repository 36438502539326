import { keyframes } from 'styled-components'

export const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const RotateIn = keyframes`
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0);
  }
`

export const FadeOutHide = keyframes`
  0% {
    opacity: 1;
    max-height: 50vh;
  }
  65% {
    max-height: 50vh;
    opacity: 0;
  }
  100% {
    max-height: 0vh;
    opacity: 0;
  }
`

export const ColorFillScreen = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  15% {
    opacity: .4;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
`
