import firebase from 'firebase'
import FirebaseModel from './FirebaseModel'

const PATH = 'results'
const REF = firebase.database().ref(PATH)

export default class TestModel extends FirebaseModel {
  static submit = (personalityType) => {
    const resultsRef = firebase.database().ref(`results/`)
    const resultsKey = resultsRef.push().key
    const timestamp = new Date().getTime()

    return REF.child(resultsKey).set({
      personalityType,
      timestamp
    })
  }
}
