import React, { Component } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

import * as S from './styles'

const mailchimpUrl = 'https://sixminutetest.us17.list-manage.com/subscribe/post-json?u=bc45909120a9b11a4b69a647c&id=a61e53f5c5'

const CustomForm = ({ status, message, onValidated }) => {
  let email
  const submit = () =>
    email &&
    email.value.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email.value
    })

  let statusMsg = status
  if (statusMsg === 'success') statusMsg = 'Subscribed!'
  if (message && message.includes('is already subscribed')) {
    statusMsg = 'You\'re already subscribed!'
  } else if (message && message.includes('has subscribed too many')) {
    statusMsg = 'Too many subscription attempts'
  }

  return (
    <S.StyledForm onSubmit={(e) => {
      e.preventDefault()
      submit()
    }}>
      { statusMsg
        ? <S.StyledTextField
          value={statusMsg}
          disabled
          success={status === 'success'}
        />
        : <S.StyledTextField
          ref={node => (email = node)}
          type='email'
          placeholder='Join our mailing list to learn more about you.'
          success={status === 'success'}
        /> }
      { status !== 'success'
        ? <S.StyledSubmit onClick={submit} />
        : <S.StyledCheck onClick={submit} /> }
    </S.StyledForm>
  )
}

export default class Footer extends Component {
  form = []
  renderSubHtml = () => {
    return <MailchimpSubscribe
      url={mailchimpUrl}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={formData => subscribe(formData)}
        />
      )}
    />
  }
  render () {
    return <S.Footer>
      <S.FooterContent>
        <S.Copywrite>
          <span>© {new Date().getFullYear()} Thud, Inc. | Dev by <a href='https://laith.wtf' target='_blank'>Laith.wtf</a></span>
          <div><S.StyledLink href='/privacy-policy'><span>FULL DISCLAIMER</span></S.StyledLink> | <S.StyledLink href='/privacy-policy'><span>PRIVACY POLICY</span></S.StyledLink></div>
          <S.Disclaimer>DISCLAIMER: 6 Minute Test is not a scientifically based personality test. This is a parody website.</S.Disclaimer>
        </S.Copywrite>
        { this.renderSubHtml() }
      </S.FooterContent>
    </S.Footer>
  }
}
