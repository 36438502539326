import styled from 'styled-components'

import { Animations } from 'app/styles'

export const Home = styled.div`
  animation: ${Animations.FadeIn} 1250ms forwards;
  animation-delay: 250ms;
  opacity: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`
